'use client'
import { useContext, useEffect, useState } from 'react'
import ReactLoading from 'react-loading';
import { IconContext } from "react-icons";
import { RiHomeLine, RiFileTextLine, RiImage2Line, RiDonutChartFill, RiMenuFill, RiCloseLine, RiSettings3Line } from "react-icons/ri";
import { TbDeviceDesktopCog } from "react-icons/tb"
import logo from '../../assets/images/pverde.png'
import logoMobile from '../../assets/images/phenosync.png'
import './styles.scss'
import { Link } from 'react-router-dom';
import Text from 'components/Text';
import { AuthContext } from 'contexts/auth';
import DropdownOfCompanies from 'components/DropdownOfCompanies';
import DropdownOfGreenhouses from 'components/DropdownOfGreenhouses';
import { delay } from 'utils/time';
import {
  listCompanies,
  getCompany,
  listGreenhouses,
} from 'services/api';
import { getRootVar } from 'utils/generalFunctions';

export default function Menu() {

  const [showMenu, setShowMenu] = useState()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { user, loginWithRefreshToken } = useContext(AuthContext);

  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [greenhouses, setGreenhouses] = useState([]);
  const [isLoadingGreenhouses, setIsLoadingGreenhouses] = useState(false);
  const [selectedGreenhouse, setSelectedGreenhouse] = useState(null);

  const [theme, setTheme] = useState(document.querySelector("body").getAttribute("data-theme"));

  useEffect(() => {
    const updateCompanies = async () => {
      setIsLoadingCompanies(true);

      const items = [];
      try {
        const res = await listCompanies();
        for (const company of res.data) {
          items.push({
            name: company.name,
          });
        }
      } catch (ex) {
        console.error('updateCompanies ex:', ex);
        delay(1000);
        (async () => {
          await loginWithRefreshToken();
          window.location.reload();
        })();
        return;
      }
      if (items.length > 0) {
        items.sort((a, b) => a.name.localeCompare(b.name));

        setCompanies(items);

        // configure selected company based on the last configured company
        const companyName = localStorage.getItem("companyName");
        setSelectedCompany(companyName ? items.find(item => item.name === companyName) : items[0]);
        setSelectedGreenhouse(null);
      }

      setIsLoadingCompanies(false);
    }

    const updateCompany = async () => {
      setIsLoadingCompanies(true);

      const res = await getCompany();
      setCompanies([res.data.data]);
      setSelectedCompany(res.data.data);
      setSelectedGreenhouse(null);

      setIsLoadingCompanies(false);
    }

    if (user.role === "admin") {
      updateCompanies();
    } else {
      updateCompany();
    }
  }, [
    user.username,
    user.role,
    setCompanies,
    setIsLoadingCompanies,
    setSelectedCompany,
    loginWithRefreshToken
  ]);


  useEffect(() => {
    const updateGreenhouses = async (companyName) => {
      setIsLoadingGreenhouses(true);

      const items = [];
      try {
        const res = await listGreenhouses(companyName);
        for (const greenhouse of res.data) {
          items.push({
            name: greenhouse.name,
            geometry: greenhouse.geometry_map,
            benches_geometry: greenhouse.benches_geometry_map,
            trays_of_last_scan_geometry: greenhouse.trays_of_last_scan_geometry_map,
            config: greenhouse.config,
          });
        }
      } catch (ex) {
        console.error('updateGreenhouses ex:', ex);
        return;
      }
      if (items.length > 0) {
        items.sort((a, b) => a.name.localeCompare(b.name));

        // configure selected greenhouse based on the last configured greenhouse
        const greenhouseName = localStorage.getItem("greenhouseName");

        setSelectedGreenhouse(greenhouseName ? items.find(item => item.name === greenhouseName) : items[0]);

        setGreenhouses(items);
      }

      setIsLoadingGreenhouses(false);
    }

    if (selectedCompany) {
      updateGreenhouses(selectedCompany.name);
    }
  }, [
    selectedCompany,
    setSelectedGreenhouse,
    setGreenhouses,
    setIsLoadingGreenhouses,
  ]);


  const handleChangeCompany = (e) => {
    const company = companies.find(item => item.name === e.target.closest('.dropdown-item').id);
    setSelectedCompany(company);
    setSelectedGreenhouse(null);

    // updates company in local storage
    localStorage.setItem("companyName", company.name);
  }

  const handleChangeGreenhouse = (e) => {
    const greenhouse = greenhouses.find(item => item.name === e.target.closest('.dropdown-item').id);
    setSelectedGreenhouse(greenhouse);

    // updates greenhouse in local storage
    localStorage.setItem("greenhouseName", greenhouse.name);
  }

  useEffect(() => {
    const handleThemeChange = () => {
      const newTheme = localStorage.getItem("selectedTheme");
      setTheme(newTheme);
      document.querySelector("body").setAttribute("data-theme", newTheme);
    };

    window.addEventListener("themeChanged", handleThemeChange);

    return () => {
      window.removeEventListener("themeChanged", handleThemeChange);
    };
  }, []);

  return (
    window.innerWidth > 980 ?
      <div
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
        className="menu"
        style={{
          backgroundColor: theme === "dark" ? getRootVar("--altWhite") : getRootVar("--white"),
          border: theme === "dark" ? getRootVar("--altWhite") : `1px solid ${getRootVar("--altWhite")}`,
        }}
      >
        <div>
          <div style={{ gap: "12px", border: theme === "dark" ? "none" : `1px solid ${getRootVar("--altWhite")}` }} className="d-flex modules logo align-items-center">
            <img
              src={logo}
              alt="Phenosync"
              style={{ width: '25px', height: 'auto' }}
            />
            {showMenu && (
              <>
                <div className="title d-flex flex-column">
                  <Text className="bold primaryTxt">Phenosync</Text>
                </div>
              </>
            )}
          </div>
          <Link to="/">
            <div className={`d-flex align-items-center ${window.location.pathname === "/" ? "active" : "menu-item"}`}>
              <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                <RiHomeLine size={25} />
              </IconContext.Provider>
              {showMenu && (
                <div className="title">
                  <Text>Página inicial</Text>
                </div>
              )}
            </div>
          </Link>
          <Link to="/reports">
            <div className={`d-flex align-items-center ${window.location.pathname === "/reports" ? "active" : "menu-item"}`}>
              <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                <RiFileTextLine size={25} />
              </IconContext.Provider>
              {showMenu && (
                <div className="title">
                  <Text>Relatórios</Text>
                </div>
              )}
            </div>
          </Link>
          <Link to="/gallery">
            <div className={`d-flex align-items-center ${window.location.pathname === "/gallery" ? "active" : "menu-item"}`}>
              <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                <RiImage2Line size={25} />
              </IconContext.Provider>
              {showMenu && (
                <div className="title">
                  <Text>Galeria</Text>
                </div>
              )}
            </div>
          </Link>
          <Link to="/timelapse">
            <div className={`d-flex align-items-center ${window.location.pathname === "/timelapse" ? "active" : "menu-item"}`}>
              <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                <RiDonutChartFill size={25} />
              </IconContext.Provider>
              {showMenu && (
                <div className="title">
                  <Text>Painéis</Text>
                </div>
              )}
            </div>
          </Link>
          {user?.role === 'admin' &&
            <Link to="/systems">
              <div className={`d-flex align-items-center ${window.location.pathname === "/systems" ? "active" : "menu-item"}`}>
                <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                  <TbDeviceDesktopCog size={25} />
                </IconContext.Provider>
                {showMenu && (
                  <div className="title">
                    <Text>Sistemas</Text>
                  </div>
                )}

              </div>
            </Link>}
          <Link to="/settings">
            <div className={`d-flex align-items-center ${window.location.pathname === "/settings" ? "active" : "menu-item"}`}>
              <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                <RiSettings3Line size={25} />
              </IconContext.Provider>
              {showMenu && (
                <div className="title">
                  <Text>Configurações</Text>
                </div>
              )}
            </div>
          </Link>
        </div>
        {showMenu && (
          <div className='d-flex flex-column gap-2' style={{ padding: "16px 24px" }}>
            <div className="sub-items">
              {isLoadingGreenhouses ? (
                <ReactLoading
                  type={"spin"}
                  color={getRootVar("--white")}
                  height={24}
                  width={24}
                />
              ) : (
                <DropdownOfGreenhouses
                  items={greenhouses}
                  selectedItem={selectedGreenhouse}
                  onClickItem={handleChangeGreenhouse}
                />
              )}
            </div>
            <div className="sub-items">
              {user?.role === "admin" ? (
                isLoadingCompanies ? (
                  <ReactLoading
                    type={"spin"}
                    color={getRootVar("--gray3")}
                    height={24}
                    width={24}
                  />
                ) : (
                  <DropdownOfCompanies
                    items={companies}
                    selectedItem={selectedCompany}
                    onClickItem={handleChangeCompany}
                  />
                )
              ) : (
                <div className='d-flex align-items-center gap-2'>
                  <Text>Empresa</Text>
                  <Text>{user ? user.companyName : "N/A"}</Text>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      : (
        showMobileMenu ? (
          <div className='openMobileMenu'>
            <div className="menuHeader">
              <div onClick={() => setShowMobileMenu(false)}>
                <RiCloseLine color={getRootVar("--gray3")} size={30} />
              </div>
              <div className='logoMobile'>
                <img
                  src={logoMobile}
                  alt="Phenosync"
                  style={{ height: '14px' }}
                />
              </div>
            </div>
            <div className="menuContent">
              <div className="mobileItemMenu">
                {user?.role === "admin" ? (
                  isLoadingCompanies ? (
                    <ReactLoading
                      type={"spin"}
                      color={getRootVar("--gray3")}
                      height={24}
                      width={24}
                    />
                  ) : (
                    <DropdownOfCompanies
                      items={companies}
                      selectedItem={selectedCompany}
                      onClickItem={handleChangeCompany}
                    />
                  )
                ) : (
                  <Text>
                    Empresa: {user ? user.companyName : "N/A"}
                  </Text>
                )}
              </div>
              <Link to="/">
                <div onClick={() => setShowMobileMenu(false)} className={`${window.location.pathname === "/" ? "mobileActive" : "mobileItemMenu"}`}>
                  <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                    <RiHomeLine size={25} color={window.location.pathname === "/" ? "white" : getRootVar("--gray3")} />
                  </IconContext.Provider>
                  <Text>Página inicial</Text>
                </div>
              </Link>
              <Link to="/reports">
                <div onClick={() => setShowMobileMenu(false)} className={`${window.location.pathname === "/reports" ? "mobileActive" : "mobileItemMenu"}`}>
                  <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                    <RiFileTextLine size={25} color={window.location.pathname === "/reports" ? "white" : getRootVar("--gray3")} />
                  </IconContext.Provider>
                  <Text>Relatórios</Text>
                </div>
              </Link>
              <Link to="/gallery">
                <div onClick={() => setShowMobileMenu(false)} className={`${window.location.pathname === "/gallery" ? "mobileActive" : "mobileItemMenu"}`}>
                  <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                    <RiImage2Line size={25} color={window.location.pathname === "/gallery" ? "white" : getRootVar("--gray3")} />
                  </IconContext.Provider>
                  <Text>Galeria</Text>
                </div>
              </Link>
              <Link to="/timelapse">
                <div onClick={() => setShowMobileMenu(false)} className={`${window.location.pathname === "/timelapse" ? "mobileActive" : "mobileItemMenu"}`}>
                  <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                    <RiDonutChartFill size={25} color={window.location.pathname === "/timelapse" ? "white" : getRootVar("--gray3")} />
                  </IconContext.Provider>
                  <Text>Painéis</Text>
                </div>
              </Link>
              <Link to="/settings">
                <div onClick={() => setShowMobileMenu(false)} className={`${window.location.pathname === "/settings" ? "mobileActive" : "mobileItemMenu"}`}>
                  <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                    <RiSettings3Line size={25} color={window.location.pathname === "/settings" ? "white" : getRootVar("--gray3")} />
                  </IconContext.Provider>
                  <Text>Configurações</Text>
                </div>
              </Link>
              {user?.role === 'admin' &&
                <Link to="/systems">
                  <div onClick={() => setShowMobileMenu(false)} className={`${window.location.pathname === "/systems" ? "active" : "mobileItemMenu"}`}>
                    <IconContext.Provider value={{ className: `icon ${theme === "dark" && "icon-dark"}` }}>
                      <TbDeviceDesktopCog size={25} color={window.location.pathname === "/systems" ? "white" : getRootVar("--gray3")} />
                    </IconContext.Provider>
                    <Text>Sistemas</Text>
                  </div>
                </Link>}
            </div>
            {
              <div className='mobileItemMenu' style={{ padding: "16px 24px", width: "100%" }}>
                <div className="sub-items">
                  {isLoadingGreenhouses ? (
                    <ReactLoading
                      type={"spin"}
                      color={getRootVar("--white")}
                      height={24}
                      width={24}
                    />
                  ) : (
                    <DropdownOfGreenhouses
                      items={greenhouses}
                      selectedItem={selectedGreenhouse}
                      onClickItem={handleChangeGreenhouse}
                    />
                  )}
                </div>
              </div>
            }
          </div >
        ) : (
          <div className='mobileMenu'>
            <div onClick={() => setShowMobileMenu(true)}>
              <RiMenuFill color={getRootVar("--gray3")} size={24} />
            </div>
            <div className='logoMobile'>
              <img
                src={logoMobile}
                alt="Phenosync"
                style={{ height: '14px' }}
              />
            </div>
          </div >
        )
      )
  )
}