import AppRoutes from 'routes';
import 'App.scss';
import { ToastContainer } from 'react-toastify';


export default function App() {
  // defines url of the distribution that will be used to get the gallery images
  document.global.gallery.itemsBaseURL = "https://d3fumqq77ukjhe.cloudfront.net";
  const theme = document.querySelector("body").getAttribute("data-theme");

  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme={theme}
        Multiline
      />
    </div>
  );
}