/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    Claro: "Claro",
    Escuro: "Escuro",
    Tema: "Tema",
    Idioma: "Idioma",
    "Português (Brasil)": "Português (Brasil)",
    "Portugués (Brasil)": "Português (Brasil)",
    "Portuguese (Brazil)": "Português (Brasil)",
    "Inglês (EUA)": "Inglês (EUA)",
    "English (USA)": "Inglês (EUA)",
    "Inglés (EUA)": "Inglês (EUA)",
    Espanhol: "Espanhol",
    Spanish: "Espanhol",
    Español: "Espanhol",
    Configurações: 'Configurações',
    Painéis: 'Painéis',
    Galeria: 'Galeria',
    Relatórios: 'Relatórios',
    Estufa: "Estufa:",
    Selecionar: "Selecionar",
    Empresa: "Empresa:",
    "Página inicial": 'Página inicial',
    "Pesquisar estufa": 'Pesquisar estufa',
    "Nenhum resultado encontrado.": 'Nenhum resultado encontrado.',
    "Experimentos ativos": 'Experimentos ativos',
    "Selecione apenas bandejas inativas para criar um novo experimento.": "Selecione apenas bandejas inativas para criar um novo experimento.",
    "Selecione as bandejas antes de criar um novo experimento.": "Selecione as bandejas antes de criar um novo experimento.",
    "Falha ao verificar as solicitações de scan correntes!": "Falha ao verificar as solicitações de scan correntes!",
    "Já existe um scan de mosaico em andamento.": "Já existe um scan de mosaico em andamento.",
    "Selecione no mapa as mesas ou estufa para criar o novo mosaico.": "Selecione no mapa as mesas ou estufa para criar o novo mosaico.",
    "Solicitação bem sucedida! Em breve o mosaico será gerado.": "Solicitação bem sucedida! Em breve o mosaico será gerado.",
    "Falha ao criar o mosaico": "Falha ao criar o mosaico",
    'Experimento deletado com sucesso': 'Experimento deletado com sucesso',
    'Não foi possível deletar este experimento': 'Não foi possível deletar este experimento',
    "Selecione pelo menos uma bandeja": "Selecione pelo menos uma bandeja",
    "Selecione apenas uma bandeja por vez": "Selecione apenas uma bandeja por vez",
    "Selecione apenas bandejas ativas": "Selecione apenas bandejas ativas",
    "Código de identificação adicionado!": "Código de identificação adicionado!",
    "Falha ao adicionar código de identificação": "Falha ao adicionar código de identificação",
    "Início": "Início",
    Nome: "Nome",
    "Data de finalização": "Data de finalização",
    "Editar/Remover": "Editar/Remover",
    Editar: "Editar",
    "Novo experimento": "Novo experimento",
    "Nenhum experimento encontrado!": "Nenhum experimento encontrado!",
    "Crie um novo experimento para ele aparecer aqui.": "Crie um novo experimento para ele aparecer aqui.",
    "Gerar mosaico": "Gerar mosaico",
    "Gerar nova solicitação de scan de mosaico?": "Gerar nova solicitação de scan de mosaico?",
    "Gerar nova solicitação": "Gerar nova solicitação",
    "Você tem certeza que deseja deletar esse experimento? Ele não será mais exibido na lista e no mapa.": "Você tem certeza que deseja deletar esse experimento? Ele não será mais exibido na lista e no mapa.",
    "Esta operação sobrescreve a solicitação anterior e é irreversível!": "Esta operação sobrescreve a solicitação anterior e é irreversível!",
    "Adicionar código de identificação": "Adicionar código de identificação",
    "Editar código de identificação": "Editar código de identificação",
    "Editar código": "Editar código",
    "Adicionar código": "Adicionar código",
    'Você está adicionando um código em uma bandeja que faz parte do experimento "{{experimentName}}".': 'Você está adicionando um código em uma bandeja que faz parte do experimento "{{experimentName}}".',
    'Você está editando um código em uma bandeja que faz parte do experimento "{{experimentName}}".': 'Você está editando um código em uma bandeja que faz parte do experimento "{{experimentName}}".',
    "Código de identificação": "Código de identificação",
    'Você já possui uma bandeja dentro do experimento "{{experimentName}}" com o código de identificação "{{code}}".': 'Você já possui uma bandeja dentro do experimento "{{experimentName}}" com o código de identificação "{{code}}".',
    "Digite o código de identificação": "Digite o código de identificação",
    Pesquisar: "Pesquisar",
    "Deletar experimento": "Deletar experimento",
    "Editar experimento": "Editar experimento",
    "Cadastrar experimento": "Cadastrar experimento",
    "Salvar alterações": "Salvar alterações",
    "Dados do experimento": "Dados do experimento",
    "Nome do experimento": "Nome do experimento",
    "Tipo de experimento": "Tipo de experimento",
    "Tipo de cultivo": "Tipo de cultivo",
    "Tamanho da unidade": "Tamanho da unidade",
    "Experimento de referência": "Experimento de referência",
    Descrição: "Descrição",
    "Dados do monitoramento": "Dados do monitoramento",
    "Data de início": "Data de início",
    "Horários de varredura": "Horários de varredura",
    "Data de plantio": "Data de plantio",
    "Digite o nome do experimento": "Digite o nome do experimento",
    "Selecione o tipo de experimento": "Selecione o tipo de experimento",
    "Selecione o tipo de cultivo": "Selecione o tipo de cultivo",
    "Selecione o tamanho": "Selecione o tamanho",
    "Selecione o experimento": "Selecione o experimento",
    "Descreva aqui o experimento": "Descreva aqui o experimento",
    "Selecione os horários": "Selecione os horários",
    "Selecione a data de plantio": "Selecione a data de plantio",
    "Selecione a data de finalização": "Selecione a data de finalização",
    "Criar experimento": "Criar experimento",
    "Se você sair desta página, as alterações realizadas não serão salvas. Tem certeza de que deseja retornar ao mapa de experimentos?": "Se você sair desta página, as alterações realizadas não serão salvas. Tem certeza de que deseja retornar ao mapa de experimentos?",
    "Você tem alterações não salvas": "Você tem alterações não salvas",
    "Retornar ao mapa de experimentos": "Retornar ao mapa de experimentos",
    "Galeria de imagens": "Galeria de imagens",
    "Período do experimento": "Período do experimento",
    "Experimento": "Experimento",
    "Tipo de foto": "Tipo de foto",
    "Consultar fotos": "Consultar fotos",
    "Selecione o período do experimento": "Selecione o período do experimento",
    "Cancelar": "Cancelar",
    "Selecionar todos ({{imagesList}})": "Selecionar todos ({{imagesList}})",
    "Deselecionar todos ({{imagesList}})": "Deselecionar todos ({{imagesList}})",
    "Baixar imagens ({{selectedNumber}})": "Baixar imagens ({{selectedNumber}})",
    "Baixar": "Baixar",
    "Sair do sistema": "Sair do sistema",
    "Exportar relatório": "Exportar relatório",
    "Gerar relatório": "Gerar relatório",
    "Método de análise": "Método de análise",
    "Cobertura foliar": "Cobertura foliar",
    "Número de plantas": "Número de plantas",
    "Índice vegetativo": "Índice vegetativo",
    "Link para imagem": "Link para imagem",
    "Nenhum relatório foi gerado!": "Nenhum relatório foi gerado!",
    "Nenhum resultado encontrado!": "Nenhum resultado encontrado!",
    "Revise as inforamações da pesquisa e tente novamente.": "Revise as inforamações da pesquisa e tente novamente.",
    'Preencha os campos acima e clique em "Gerar relatório" para aparecer aqui.': 'Preencha os campos acima e clique em "Gerar relatório" para aparecer aqui.',
    "Sim": "Sim",
    "Não": "Não",
    "Exportar em CSV": "Exportar em CSV",
    "Exportar em XLSX": "Exportar em XLSX",
    "Filtar por data": "Filtar por data",
    "Hora do scan": "Hora do scan",
    "Código da unidade": "Código da unidade",
    "Data do scan": "Data do scan",
    "Bandeja": "Bandeja",
    "Planta": "Planta",
    "Imagem": "Imagem",
    "Variança": "Variança",
    "Media": "Media",
    "Desvio padrão": "Desvio padrão",
    "Repetição": "Repetição",
    "Tratamento": "Tratamento",
    "Visualizar": "Visualizar",
    "Filtrar tabela": "Filtrar tabela",
    "Falha ao exportar relatório": "Falha ao exportar relatório",
    "Experimento destinado à continuidade das avaliações após a aplicação de um tratamento que resultou na movimentação das unidades experimentais de suas posições originais. Os dados do experimento de referência e do atual serão mantidos em conjunto, baseando-se no croqui do experimento, com os códigos inseridos em cada unidade experimental. O número de unidades experimentais no novo experimento deve ser igual ao do experimento de referência, e os códigos utilizados ao inserir o novo croqui devem ser os mesmos do experimento de referência.": "Experimento destinado à continuidade das avaliações após a aplicação de um tratamento que resultou na movimentação das unidades experimentais de suas posições originais. Os dados do experimento de referência e do atual serão mantidos em conjunto, baseando-se no croqui do experimento, com os códigos inseridos em cada unidade experimental. O número de unidades experimentais no novo experimento deve ser igual ao do experimento de referência, e os códigos utilizados ao inserir o novo croqui devem ser os mesmos do experimento de referência.",
    "Pequena: 1 foto": "Pequena: 1 foto",
    "Média: 4 fotos": "Média: 4 fotos",
    "Você tem {{time}} para editar essa informação. Após esse período, o campo será bloqueado.": "Você tem {{time}} para editar essa informação. Após esse período, o campo será bloqueado.",
    "ID por planta": "ID por planta"
  }
}