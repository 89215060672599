import { forwardRef } from 'react'
import './styles.scss'
import { getRootVar } from 'utils/generalFunctions'
import { useTranslation } from 'react-i18next';

export const Textarea = forwardRef(
  (
    {
      className,
      type,
      label,
      bootstrapClass,
      placeholder,
      style,
      value,
      width,
      onChange,
      disabled,
      ...props
    },
    ref
  ) => {
    const theme = document.querySelector("body").getAttribute("data-theme");
    const { t } = useTranslation()
    return (
      <div
        className={`${className} label-title-textarea`}
        style={{
          ...style,
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? ".6" : "1"
        }}
      >
        {label && <div>{label}</div>}
        <textarea
          style={{
            width: width,
            backgroundColor: theme === "dark" && getRootVar("--altWhite"),
            border: `1px solid ${theme === "light" ? getRootVar("--gray4") : getRootVar("--altWhite")}`
          }}
          ref={ref}
          {...props}
          className="textarea"
          value={value}
          onChange={onChange}
          placeholder={t(placeholder)}
        />
      </div>
    )
  }
)

export default Textarea
