export function getQueryStringParams() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params;
}

export function removeQueryStringParamsFromCurrentUrl() {
  window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
}

export function downloadFile(filename, content) {
  const pom = document.createElement('a');
  pom.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
  pom.setAttribute('download', filename);
  pom.click();
}