import { useState } from "react";
import { Select } from 'components/Select';
import Text from "components/Text";
import { useTranslation } from "react-i18next";

const ThemeMode = () => {
  const { t } = useTranslation()

  const themeModes = [
    { value: "light", label: t("Claro") },
    { value: "dark", label:  t("Escuro") }
  ];

  const selectedTheme = themeModes.find(option => option.value === localStorage.getItem("selectedTheme"));
  const [themeMode, setThemeMode] = useState(selectedTheme || themeModes[1]);

  const onChange = (value) => {
    setThemeMode(value);
    document.querySelector("body").setAttribute("data-theme", value.value);
    localStorage.setItem("selectedTheme", value.value);
    window.dispatchEvent(new Event("themeChanged"));
  };

  return (
    <Select
      label={<Text className="bold smallTxt">Tema</Text>}
      options={themeModes}
      value={{value: themeMode.value, label: t(themeMode.label) }}
      clear={false}
      onChange={(e) => onChange(e)}
    />
  );
};

export default ThemeMode;
