import React, { useState } from 'react';
import {
  Dropdown,
  FormControl,
} from 'react-bootstrap';

import "./index.scss";
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef((
  {
    children,
    onClick,

    isDisabled,
    isValid,
  },
  ref
) => (
  <span
    className={isDisabled ? 'text-secondary' : isValid ? 'link-white' : 'link-danger'}
    style={{
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Digite para filtrar..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => {
            if (!value || child.props.role === "heading") {
              return true;
            }
            return child.props.children.toLowerCase().startsWith(value);
          })}
        </ul>
      </div>
    );
  },
);

export default function DropdownOfCompanies({
  items,
  selectedItem = null,
  isDisabled = false,
  isValid = true,
  onToggle = null,
  onClickItem = null,
}) {
  const {t} = useTranslation()
  
  return (
    <Dropdown
      className="DropdownOfCompanies"
      autoClose="outside" 
      onToggle={onToggle} 
      title={t("Empresa")}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        isDisabled={isDisabled}
        isValid={isValid}
      >
        {selectedItem ? selectedItem.name : "Selecionar empresa"}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="DropdownMenu"
      >
        <Dropdown.Header>
          <Text>Empresas</Text>
        </Dropdown.Header>
        {items.map(item => (
          <Dropdown.Item
            as="button"
            className="Item"
            id={item.name}
            key={item.name}
            eventKey={item.name}
            active={selectedItem && item.name === selectedItem.name}
            onClick={onClickItem}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
