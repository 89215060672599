import {
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import jwt from 'jwt-decode';

import {
  api,
  createSessionWithAuthCode,
  refreshSession,
} from 'services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const configureUser = (token) => {
    // decode jwt token
    const jwtData = jwt(token);
    const role = jwtData["cognito:groups"][0];

    // use jwt data
    const loggedUser = {
      "username": jwtData["cognito:username"],
      "email": jwtData["email"],
      "role": role,
      "companyName": role === "admin" ? null : jwtData["custom:company"],
    };
    localStorage.setItem("user", JSON.stringify(loggedUser));
    setUser(loggedUser);
  }

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');

    api.defaults.headers.Authorization = null;

    setUser(null);
    setIsLoading(false);
    navigate("/login");
  }, [navigate]);


  const loginWithRefreshToken = useCallback(async () => {
    try {
      // refresh session
      const res = await refreshSession(localStorage.getItem("refreshToken"));
      if (res.status !== 201) {
        throw res;
      }
      const token = res.data.data.id_token;
      const refreshToken = res.data.data.refresh_token;

      // use tokens
      configureUser(token);
      api.defaults.headers.Authorization = `Bearer ${token}`;

      // store tokens
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
    } catch (ex) {
      if (ex.message === "Request aborted") {
        await loginWithRefreshToken();
      } else {
        console.error('ex:', ex);
        logout();
      }
    }
  }, [logout]);

  useEffect(() => {
    const recoveredUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    let refreshTokenInterval = null;
    if (recoveredUser && token) {
      setUser(JSON.parse(recoveredUser));
      api.defaults.headers.Authorization = `Bearer ${token}`;
      loginWithRefreshToken();
      refreshTokenInterval = setInterval(loginWithRefreshToken, 1 * 60 * 60 * 1000); // 1h * 60m * 60s * 1000ms
    }

    setIsLoading(false);

    return () => {
      clearInterval(refreshTokenInterval);
    }
  }, [loginWithRefreshToken]);

  const loginWithAuthorizationCode = async (code) => {
    setIsLoading(true);

    await Swal.fire({
      title: 'Fazendo o login...',
      allowOutsideClick: false,
      showCloseButton: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          // create session
          const res = await createSessionWithAuthCode(code);
          if (res.status !== 201) {
            throw res;
          }
          const token = res.data.data.id_token;
          const refreshToken = res.data.data.refresh_token;

          // use tokens
          configureUser(token);
          api.defaults.headers.Authorization = `Bearer ${token}`;

          // store tokens
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);

          setIsLoading(false);

          navigate("/");
        } catch (ex) {
          console.error('ex:', ex);
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.message === "Network Error" ? 'Erro de conexão! Por favor, tente novamente.'
              : "Ocorreu um erro inesperado... Por favor, tente novamente.",
          });
          logout();
        }
        Swal.close();
      }
    });
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated: !!user,
        user,
        loginWithAuthorizationCode,
        loginWithRefreshToken,
        logout,
        navigate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
