import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import {
  RiCloseLargeLine,
  RiDownload2Line,
  RiFullscreenLine,
  RiZoomInLine,
  RiZoomOutLine,
} from "react-icons/ri";
import { getRootVar } from "utils/generalFunctions";
import Text from "components/Text";

const SliderModal = ({
  initialIndex,
  onClose,
  items,
  selectedRadioOfImageType,
  setSelectedRadioOfImageType,
  onChange,
  handleDownloadImage,
  formatDate
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(initialIndex || 0);
  const [images, setImages] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const thumbnailsPerPage = window.innerWidth > 700 ? 5 : 3;
  const imgRef = useRef(null);

  useEffect(() => {
    setActiveImageIndex(initialIndex);
  }, [initialIndex]);

  const handleNextImage = () => {
    setActiveImageIndex((prevIndex) => {
      const newIndex = prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      return newIndex;
    });
  };

  const handlePreviousImage = () => {
    setActiveImageIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? images.length - 1 : prevIndex - 1;
      return newIndex;
    });
  };

  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  const getThumbnailIndices = () => {
    const totalImages = images.length;
    const indices = [];

    const startIndex = Math.max(0, activeImageIndex - 2);
    const endIndex = Math.min(totalImages - 1, activeImageIndex + 2);

    for (let i = startIndex; i <= endIndex; i++) {
      indices.push(i);
    }

    if (indices.length < thumbnailsPerPage) {
      if (startIndex === 0) {
        for (let i = endIndex + 1; indices.length < thumbnailsPerPage && i < totalImages; i++) {
          indices.push(i);
        }
      } else {
        for (let i = startIndex - 1; indices.length < thumbnailsPerPage && i >= 0; i--) {
          indices.unshift(i);
        }
      }
    }

    return indices.slice(0, thumbnailsPerPage);
  };

  useEffect(() => {
    const images = items?.map((image) => {
      if (selectedRadioOfImageType.value === "tray") {
        return image.url;
      } else if (selectedRadioOfImageType.value === "mask") {
        return image.color_mask_url;
      } else {
        return image.raw_url;
      }
    });

    setImages(images);
  }, [items, selectedRadioOfImageType]);


  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1));
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (imgRef.current.requestFullscreen) {
        imgRef.current.requestFullscreen();
      } else if (imgRef.current.mozRequestFullScreen) {
        imgRef.current.mozRequestFullScreen();
      } else if (imgRef.current.webkitRequestFullscreen) {
        imgRef.current.webkitRequestFullscreen();
      } else if (imgRef.current.msRequestFullscreen) {
        imgRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const thumbnailIndices = getThumbnailIndices();

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ padding: window.innerWidth < 980 && "0px" }}>
        {window.innerWidth < 980 ? (
          <div className="sliderHeader" style={{ flexDirection: "column" }}>
            <div className="d-flex align-items-center gap-2 justify-content-between position-relative" style={{ width: "100%" }}>
              <Text className="white" style={{ position: "absolute", }}>{items.map((item, index) => (activeImageIndex === index ? formatDate(item.DTime) : null))}</Text>
              <RiCloseLargeLine
                className="icon"
                size="20"
                color="white"
                onClick={onClose}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
              <div className="d-flex align-items-center gap-2">
                <div
                  className={
                    selectedRadioOfImageType.value === "raw"
                      ? "imageTypeActive"
                      : "imageType"
                  }
                  onClick={() =>
                    onChange({ label: "Raw", value: "raw" }, setSelectedRadioOfImageType)
                  }
                >
                  <Text className="smallTxt">Raw</Text>
                </div>
                <div
                  className={
                    selectedRadioOfImageType.value === "mask"
                      ? "imageTypeActive"
                      : "imageType"
                  }
                  onClick={() =>
                    onChange({ label: "Mask", value: "mask" }, setSelectedRadioOfImageType)
                  }
                >
                  <Text className="smallTxt">Mask</Text>
                </div>
                <div
                  className={
                    selectedRadioOfImageType.value === "tray"
                      ? "imageTypeActive"
                      : "imageType"
                  }
                  onClick={() =>
                    onChange({ label: "Tray", value: "tray" }, setSelectedRadioOfImageType)
                  }
                >
                  <Text className="smallTxt">Tray</Text>
                </div>
              </div>
              <RiDownload2Line
                className="icon"
                size="22"
                color={getRootVar("--secondary")}
                onClick={() => handleDownloadImage(images[activeImageIndex])}
              />
            </div>
          </div>
        ) : (
          <div className="sliderHeader">
            <div className="d-flex align-items-center gap-2">
              <Text className="white">{items.map((item, index) => (activeImageIndex === index ? formatDate(item.DTime) : null))}</Text>
            </div>
            <div className="d-flex align-items-center gap-2">
              <div
                className={
                  selectedRadioOfImageType.value === "raw"
                    ? "imageTypeActive"
                    : "imageType"
                }
                onClick={() =>
                  onChange({ label: "Raw", value: "raw" }, setSelectedRadioOfImageType)
                }
              >
                <Text className="smallTxt">Raw</Text>
              </div>
              <div
                className={
                  selectedRadioOfImageType.value === "mask"
                    ? "imageTypeActive"
                    : "imageType"
                }
                onClick={() =>
                  onChange({ label: "Mask", value: "mask" }, setSelectedRadioOfImageType)
                }
              >
                <Text className="smallTxt">Mask</Text>
              </div>
              <div
                className={
                  selectedRadioOfImageType.value === "tray"
                    ? "imageTypeActive"
                    : "imageType"
                }
                onClick={() =>
                  onChange({ label: "Tray", value: "tray" }, setSelectedRadioOfImageType)
                }
              >
                <Text className="smallTxt">Tray</Text>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <RiDownload2Line
                className="icon"
                size="22"
                color={getRootVar("--secondary")}
                onClick={() => handleDownloadImage(images[activeImageIndex])}
              />
              <RiZoomOutLine
                className="icon"
                size="20"
                color="white"
                onClick={handleZoomOut}
                style={{ opacity: zoomLevel === 1 ? .7 : 1 }}
              />
              <RiZoomInLine
                className="icon"
                size="20"
                color="white"
                onClick={handleZoomIn}
                style={{ opacity: zoomLevel === 3 ? .7 : 1 }}
              />
              <RiFullscreenLine
                className="icon"
                size="20"
                color="white"
                onClick={toggleFullscreen}
              />
              <RiCloseLargeLine
                className="icon"
                size="20"
                color="white"
                onClick={onClose}
              />
            </div>
          </div>
        )}

        <div className="main-image-container">
          <button className="arrow left-arrow" onClick={handlePreviousImage}>
            &#10094;
          </button>
          <div className="main-image">
            <img
              ref={imgRef}
              src={images[activeImageIndex]}
              alt={`Image ${activeImageIndex + 1}`}
              style={{ transform: `scale(${zoomLevel})` }}
            />
          </div>
          <button className="arrow right-arrow" onClick={handleNextImage}>
            &#10095;
          </button>
        </div>
        <div className="thumbnails-container">
          {thumbnailIndices.map((index) => (
            <img
              key={index}
              src={images[index]}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${activeImageIndex === index ? "active" : ""}`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderModal;
