/* eslint-disable import/no-anonymous-default-export */
export default {
    translations: {
        Claro: "Light",
        Escuro: "Dark",
        Tema: "Theme",
        Idioma: "Language",
        "Português (Brasil)": "Portuguese (Brazil)",
        "Portuguese (Brazil)": "Portuguese (Brazil)",
        "Portugués (Brasil)": "Portuguese (Brazil)",
        "Inglês (EUA)": "English (USA)",
        "English (USA)": "English (USA)",
        "Inglés (EUA)": "English (USA)",
        Espanhol: "Spanish",
        Spanish: "Spanish",
        Español: "Spanish",
        Configurações: 'Settings',
        Painéis: 'Dashboards',
        Galeria: 'Gallery',
        Relatórios: 'Reports',
        Estufa: "Greenhouse:",
        Selecionar: "Select",
        Empresa: "Company:",
        "Página inicial": 'Home',
        "Pesquisar estufa": 'Search greenhouse',
        "Nenhum resultado encontrado.": 'No results found.',
        "Experimentos ativos": 'Active experiments',
        "Selecione apenas bandejas inativas para criar um novo experimento.": "Select only inactive trays to create a new experiment.",
        "Selecione as bandejas antes de criar um novo experimento.": "Select the trays before creating a new experiment.",
        "Falha ao verificar as solicitações de scan correntes!": "Failed to check current scan requests!",
        "Já existe um scan de mosaico em andamento.": "A mosaic scan is already in progress.",
        "Selecione no mapa as mesas ou estufa para criar o novo mosaico.": "Select tables or greenhouse on the map to create the new mosaic.",
        "Solicitação bem sucedida! Em breve o mosaico será gerado.": "Request successful! The mosaic will be generated soon.",
        "Falha ao criar o mosaico": "Failed to create the mosaic",
        'Experimento deletado com sucesso': 'Experiment successfully deleted',
        'Não foi possível deletar este experimento': 'Could not delete this experiment',
        "Selecione pelo menos uma bandeja": "Select at least one tray",
        "Selecione apenas uma bandeja por vez": "Select only one tray at a time",
        "Selecione apenas bandejas ativas": "Select only active trays",
        "Código de identificação adicionado!": "Identification code added!",
        "Falha ao adicionar código de identificação": "Failed to add identification code",
        "Início": "Home",
        Nome: "Name",
        "Data de finalização": "End date",
        "Editar/Remover": "Edit/Remove",
        Editar: "Edit",
        "Novo experimento": "New experiment",
        "Nenhum experimento encontrado!": "No experiments found!",
        "Crie um novo experimento para ele aparecer aqui.": "Create a new experiment to see it listed here.",
        "Gerar mosaico": "Generate mosaic",
        "Você tem certeza que deseja deletar esse experimento? Ele não será mais exibido na lista e no mapa.": "Are you sure you want to delete this experiment? It will no longer be shown in the list and on the map.",
        "Esta operação sobrescreve a solicitação anterior e é irreversível!": "This operation overrides the previous request and is irreversible!",
        "Editar código de identificação": "Edit identification code",
        "Editar código": "Edit code",
        "Adicionar código de identificação": "Add identification code",
        "Adicionar código": "Add code",
        "Gerar nova solicitação de scan de mosaico?": "Generate a new mosaic scan request?",
        "Gerar nova solicitação": "Generate a new request",
        'Você está adicionando um código em uma bandeja que faz parte do experimento "{{experimentName}}".': 'You are adding a code to a tray that is part of the experiment "{{experimentName}}".',
        'Você está editando um código em uma bandeja que faz parte do experimento "{{experimentName}}".': 'You are editing a code to a tray that is part of the experiment "{{experimentName}}".',
        "Código de identificação": "Identification code",
        'Você já possui uma bandeja dentro do experimento "{{experimentName}}" com o código de identificação "{{code}}".': 'You already have a tray in the experiment "{{experimentName}}"" with the identification code "{{code}}".',
        'teste "{{experimentName}}".': 'testing "{{experimentName}}".',
        "Digite o código de identificação": "Enter the identification code",
        Pesquisar: "Search",
        "Deletar experimento": "Delete experiment",
        "Editar experimento": "Edit experiment",
        "Cadastrar experimento": "Register experiment",
        "Salvar alterações": "Save changes",
        "Dados do experimento": "Experiment data",
        "Nome do experimento": "Experiment name",
        "Tipo de experimento": "Experiment type",
        "Tipo de cultivo": "Farming type",
        "Tamanho da unidade": "Unit size",
        "Experimento de referência": "Reference experiment",
        "Descrição": "Description",
        "Dados do monitoramento": "Monitoring data",
        "Data de início": "Start date",
        "Horários de varredura": "Scan times",
        "Data de plantio": "Planting date",
        "Digite o nome do experimento": "Enter the experiment name",
        "Selecione o tipo de experimento": "Select the experiment type",
        "Selecione o tipo de cultivo": "Select the farming type",
        "Selecione o tamanho": "Select the size",
        "Selecione o experimento": "Select the experiment",
        "Descreva aqui o experimento": "Describe the experiment here",
        "Selecione os horários": "Select the times",
        "Selecione a data de plantio": "Select the planting date",
        "Selecione a data de finalização": "Select the end date",
        "Criar experimento": "Create experiment",
        "Se você sair desta página, as alterações realizadas não serão salvas. Tem certeza de que deseja retornar ao mapa de experimentos?": "If you leave this page, the changes made will not be saved. Are you sure you want to return to the experiment map?",
        "Você tem alterações não salvas": "You have unsaved changes",
        "Retornar ao mapa de experimentos": "Return to the experiment map",
        "Galeria de imagens": "Image gallery",
        "Período do experimento": "Experiment range",
        "Experimento": "Experiment",
        "Tipo de foto": "Photo type",
        "Consultar fotos": "Search photos",
        "Selecione o período do experimento": "Select the experiment range",
        "Cancelar": "Cancel",
        "Selecionar todos ({{imagesList}})": "Select all ({{imagesList}})",
        "Deselecionar todos ({{imagesList}})": "Deselect all ({{imagesList}})",
        "Baixar imagens ({{selectedNumber}})": "Download images ({{selectedNumber}})",
        "Baixar": "Download",
        "Sair do sistema": "Logout",
        "Exportar relatório": "Export report",
        "Gerar relatório": "Generate report",
        "Método de análise": "Analysis method",
        "Cobertura foliar": "Leaf coverage",
        "Número de plantas": "Number of plants",
        "Índice vegetativo": "Vegetative index",
        "Link para imagem": "Image link",
        "Nenhum relatório foi gerado!": "No report has been generated!",
        "Nenhum resultado encontrado!": "No results found!",
        "Revise as inforamações da pesquisa e tente novamente.": "Review the search information and try again.",
        'Preencha os campos acima e clique em "Gerar relatório" para aparecer aqui.': 'Fill in the fields above and click "Generate report" to display here.',
        "Sim": "Yes",
        "Não": "No",
        "Exportar em CSV": "Export to CSV",
        "Exportar em XLSX": "Export to XLSX",
        "Filtar por data": "Filter by date",
        "Hora do scan": "Scan time",
        "Código da unidade": "Unit code",
        "Data do scan": "Scan date",
        "Bandeja": "Tray",
        "Planta": "Plant",
        "Imagem": "Image",
        "Variança": "Variance",
        "Media": "Mean",
        "Desvio padrão": "Standard deviation",
        "Repetição": "Replication",
        "Tratamento": "Treatment",
        "Visualizar": "View",
        "Filtrar tabela": "Filter table",
        "Falha ao exportar relatório": "Failed to export report",
        "Experimento destinado à continuidade das avaliações após a aplicação de um tratamento que resultou na movimentação das unidades experimentais de suas posições originais. Os dados do experimento de referência e do atual serão mantidos em conjunto, baseando-se no croqui do experimento, com os códigos inseridos em cada unidade experimental. O número de unidades experimentais no novo experimento deve ser igual ao do experimento de referência, e os códigos utilizados ao inserir o novo croqui devem ser os mesmos do experimento de referência.": "Experiment intended for the continuation of evaluations after the application of a treatment that resulted in the movement of experimental units from their original positions. The data from the reference experiment and the current one will be kept together, based on the experiment's layout, with the codes entered in each experimental unit. The number of experimental units in the new experiment must be the same as in the reference experiment, and the codes used when inserting the new layout must be the same as those from the reference experiment.",
        "Pequena: 1 foto": "Small: 1 photo",
        "Média: 4 fotos": "Medium: 4 photos",
        "Você tem {{time}} para editar essa informação. Após esse período, o campo será bloqueado.": "You have {{time}} to edit this information. After this period, the field will be locked.",
        "ID por planta": "ID by plant"
    }
}
