import axios from 'axios';
import { format, addDays } from 'date-fns';


export const api = axios.create({
  baseURL: "https://estufa-live.smartestufa.com",
  validateStatus: (status) => status >= 200 && status <= 300,
});
document.global.api = api;


// USER:

export const createSession = async (username, password) => {
  return api.post("/session", { username, password });
}


export const createSessionWithAuthCode = async (code) => {
  return api.post("/session/code", { code });
}


export const refreshSession = async (refreshToken) => {
  return api.post("/session/refresh", { refresh_token: refreshToken });
}


// COMPANY:

const getUrlOfCompanies = async () => {
  return api.get("/list/company");
}

export const listCompanies = async () => {
  let res = await getUrlOfCompanies();
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const getCompany = async (companyName = null) => {
  return api.get("/company", {
    params: {
      company: companyName,
    }
  });
}


//GREENHOUSE:

const getUrlOfGreenhouses = async (companyName) => {
  return api.get("/list/greenhouse", {
    params: {
      company: companyName,
      attributes: [
        "name",
        "geometry_map",
        "benches_geometry_map",
        "trays_of_last_scan_geometry_map",
        "config",
      ].join(","),
    }
  });
}

export const listGreenhouses = async (companyName) => {
  let res = await getUrlOfGreenhouses(companyName);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const updateGreenhouse = async (
  companyName,
  greenhouseName,
  fields,
) => {
  let res = await api.put(
    `/greenhouse/${greenhouseName}`,
    fields,
    {
      params: {
        company: companyName,
      },
    }
  );
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const getGreenhouse = async (companyName, greenhouseName) => {
  if (greenhouseName) {
    return api.get(`/greenhouse/${greenhouseName}`, {
      params: {
        company: companyName,
      }
    });
  }
}

export const handleSelectOptions = async (companyName, greenhouseName, obj) => {
  let res = await getGreenhouse(companyName, greenhouseName);
  const config = res?.data.data.config;

  // Função para converter um objeto em um array de {label, value}
  const convertToOptions = (data) => {
    if (data) {
      if (Array.isArray(data)) {
        return data.map(item => ({ label: item, value: item }));
      } else {
        return Object.entries(data).map(([value, label]) => ({ label, value }));
      }
    }
  };

  const selectedOptions = config && convertToOptions(config[obj]);
  return selectedOptions;
};

export const getCompletedScans = async (
  companyName,
  greenhouseName,
  initial_date,
  end_date,
  status

) => {

  return api.get(`/greenhouses/${greenhouseName}/gcodes`, {
    params: {
      company: companyName,
      active: false,
      scan_type: "trays",
      initial_date: initial_date,
      end_date: end_date,
      scan_status: status,
    }
  });

}

const getUrlOfGreenhouseMapGeojson = async (companyName, greenhouseName) => {
  return api.get(`/greenhouse/geojson/${greenhouseName}`, {
    params: {
      company: companyName,
    }
  });
}

export const getGreenhouseMapGeojson = async (companyName, greenhouseName) => {
  let res = await getUrlOfGreenhouseMapGeojson(companyName, greenhouseName);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


// Gcode:
export const createGcode = async (companyName, greenhouseName, gcode, canReplaceNotStartedGcodes) => {
  let res = await api.post(`/greenhouses/${greenhouseName}/gcodes`, gcode, {
    params: {
      company: companyName,
      can_replace_not_started_gcodes: canReplaceNotStartedGcodes,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
}


export const listGcodes = async (
  companyName,
  greenhouseName,
  active = true,
  scanType = null,
  scanStatus = null,
  startDate = null,
  endDate = null,
  attributes = [
    "uuid",
    "status",
    "s3_key",
    "scan_type",
    "scan_time",
    "scan_duration",
    "expected_pictures_taken",
    "pictures_taken",
    "mosaic_uuid",
  ],
) => {
  let res = await api.get(`/greenhouses/${greenhouseName}/gcodes`, {
    params: {
      company: companyName,
      active: active,
      scan_type: scanType,
      scan_status: scanStatus,
      start_date: startDate,
      end_date: endDate,
      attributes: attributes.join(","),
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


// MOSAIC:
const getUrlOfMosaics = async (companyName, greenhouseName) => {
  return api.get(`/greenhouses/${greenhouseName}/mosaics`, {
    params: {
      company: companyName,
    },
  });
}

export const listMosaics = async (companyName, greenhouseName) => {
  let res = await getUrlOfMosaics(companyName, greenhouseName);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


const getUrlOfLayerControlData = async (companyName, greenhouseName) => {
  return api.get(`/greenhouses/${greenhouseName}/layer_control_data`, {
    params: {
      company: companyName,
    },
  });
}

export const getLayerControlData = async (companyName, greenhouseName) => {
  let res = await getUrlOfLayerControlData(companyName, greenhouseName);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const getGreenhouseCameraUrl = async () => {
  return api.get(`/greenhouse/camera/url`);
}


// EXPERIMENT:

const getUrlOfExperiments = async (
  companyName,
  greenhouseName,
  active = false,
  startDate = null,
  endDate = null,
  closingDate = null,
) => {
  /*
  if active=true: get all active experiments 
  else: get all experiments (active or not)
  */
  return api.get(`/experiment/greenhouse/${greenhouseName}`, {
    params: {
      company: companyName,
      active: active,
      initial_date: startDate ? format(startDate, 'yyyyMMdd') : null,
      end_date: endDate ? format(endDate, 'yyyyMMdd') : null,
      closing_date: closingDate ? format(closingDate, 'yyyyMMdd') : null,
    }
  });
}

export const listExperiments = async (
  companyName,
  greenhouseName,
  active = false,
  startDate = null,
  endDate = null,
  closingDate = null,
) => {
  let res = await getUrlOfExperiments(
    companyName,
    greenhouseName,
    active,
    startDate,
    endDate,
    closingDate,
  );

  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const getExperiment = async (companyName, greenhouseName, experimentUuid) => {
  let res = await api.get(`/experiment/${experimentUuid}/greenhouse/${greenhouseName}`, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const createExperiment = async (companyName, experiment) => {
  let res = await api.post('/experiment', experiment, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
}


export const updateExperiment = async (companyName, greenhouseName, experimentUuid, experiment) => {
  let res = await api.put(`/experiment/${experimentUuid}/greenhouse/${greenhouseName}`, experiment, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


// TRAY:

const getUrlOfActiveTraysAtPosition = async (companyName, greenhouseName) => {
  return api.get(`/tray/greenhouse/${greenhouseName}`, {
    params: {
      company: companyName,
    }
  });
}

export const listActiveTraysAtPosition = async (companyName, greenhouseName) => {
  let res = await getUrlOfActiveTraysAtPosition(companyName, greenhouseName);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


const getUrlOfCurrentTrays = async (companyName, greenhouseName) => {
  return api.get(`/tray/current/greenhouse/${greenhouseName}`, {
    params: {
      company: companyName,
      attributes: [
        "uuid",
        "geohash",
        "position",
        "geometry_map",
        "shape_type",
        "size",
        "experiment_uuid",
        "bench",
        "code",
        "treatment",
        "replicate",
        "active",
        "current",
        "predicted",
        "validated",
      ].join(","),
      refresh: true,
    }
  });
}

export const listCurrentTrays = async (companyName, greenhouseName) => {
  let res = await getUrlOfCurrentTrays(companyName, greenhouseName);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


const getUrlOfExperimentTrays = async (companyName, experimentUuid) => {
  return api.get(`/tray/experiment_uuid/${experimentUuid}`, {
    params: {
      company: companyName,
      attributes: [
        "uuid",
        "geohash",
        "position",
        "code",
        "treatment",
        "replicate",
        "size",
        "geometry",
        "geometry_map",
        "shape_type",
        "experiment_uuid",
        "bench",
        "active",
        "current",
        "predicted",
        "validated",
        "cropping_method",
      ].join(","),
    }
  });
}

export const listExperimentTrays = async (companyName, experimentUuid) => {
  let res = await getUrlOfExperimentTrays(companyName, experimentUuid);
  if (res.status !== 200) {
    throw res;
  }
  const jsonUrl = res.data.data;
  if (typeof (jsonUrl) === "object") {
    return { data: [] };
  }
  res = await axios.get(jsonUrl);
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const getMultipleTrays = async (
  companyName,
  greenhouseName,
  trayUuidList,
  trayAttributes = [
    'uuid',
    'code',
  ],
) => {
  let res = await api.post(`/tray/multiple/get`, trayUuidList, {
    params: {
      company: companyName,
      greenhouse: greenhouseName,
      tray_attributes: trayAttributes.join(','),
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const createTray = async (companyName, tray) => {
  let res = await api.post('/tray', tray, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
}

export const updateTray = async (companyName, greenhouseName, tray_uuid, tray) => {
  let res = await api.put(`/tray/greenhouse/${greenhouseName}/tray_uuid/${tray_uuid}`,
    tray,
    {
      params: {
        company: companyName,
        greenhouse: greenhouseName,
        tray_uuid: tray_uuid
      },
    });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const updateMultipleTrays = async (companyName, greenhouseName, trays) => {
  let res = await api.put(`/tray/multiple`, trays, {
    params: {
      company: companyName,
      greenhouse: greenhouseName,
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const downloadCroquiModel = async (
  companyName,
  greenhouseName,
  fileFormat = "CSV", // CSV, XLSX
) => {
  let res = await api.get(`/tray/greenhouse/${greenhouseName}/croqui`, {
    params: {
      company: companyName,
      file_format: fileFormat,
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const createUploadUrlOfCroquiFile = async (companyName, greenhouseName) => {
  let res = await api.post(`/tray/greenhouse/${greenhouseName}/croqui`, null, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
}


export const uploadCroquiFile = async (companyName, greenhouseName, file, csvData) => {
  // treat csvData
  csvData = csvData.replaceAll(";", ",");

  let res = await createUploadUrlOfCroquiFile(companyName, greenhouseName);
  const data = res.data.data;
  const uploadUrl = data.url;
  const uploadFields = new FormData();
  for (const key in data.fields) {
    uploadFields.append(key, data.fields[key]);
  }
  uploadFields.set("file", csvData);
  const keySplit = uploadFields.get("key").split("/");
  keySplit.pop();
  const key = `${keySplit.join("/")}/${file.name}`;
  uploadFields.set("key", key);

  res = await axios.post(uploadUrl, uploadFields, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
  if (res.status !== 204) { // 204 No Content
    throw res;
  }
  res.data = { "s3_key": key };
  return res;
}


// IMAGE:

export const getAccessToBucketImages = async (companyName, greenhouseName) => {
  const res = await api.get(`/images/greenhouse/${greenhouseName}`, {
    params: {
      company: companyName,
    }
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const listExperimentTrayImages = async (
  companyName,
  experimentUuid,
  startDate = null,
  endDate = null,
) => {
  let res = null;
  const data = {
    "message": null,
    "data": [],
  };

  // Loop to perform pagination in the database
  let lastEvaluetedKey = null;
  while (true) {
    res = await api.get(`/images/tray/experiment_uuid/${experimentUuid}`, {
      params: {
        company: companyName,
        initial_date: startDate ? format(startDate, 'yyyyMMdd') : null,
        end_date: endDate ? format(addDays(endDate, 1), 'yyyyMMdd') : null,
        pk: lastEvaluetedKey?.pk,
        sk: lastEvaluetedKey?.sk,
        attributes: [
          "pk",
          "sk",
          "timestamp",
          "geohash",
          "experiment_uuid",
          "qr_code",
          "position",
          "bounds",
          "pixel_size",
          "plant_ammount",
          "leaf_area_index",
          "plants",
          "md5_checksum",
          "raw_img_db_key",
          "predictions",
          "annotations",
          "was_annotated",
          "cropping_method",
          "tray_uuid",
          "gcode_uuid",
          "scan_type",
          "geometry",
          "s3_key",
          // "url",
          // "thumbnail_url",
          // "color_mask_url",
          // "color_thumbnail_url",
        ].join(","),
      }
    });

    if (res.status !== 200) {
      throw res;
    }

    data.message = res.data.message;
    data.data.push(...res.data.data.items);
    lastEvaluetedKey = res.data.data.last_evalueted_key;
    if (!lastEvaluetedKey) {
      break;
    }
  }
  res.data = data;
  return res;
}


export const getUrlOfExperimentResults = async (
  companyName,
  greenhouseName,
  experimentUuid,
  reportType,
  fileType,
  analisisType,
  includePreviousExperiments = true,
  addColumnPlantAmmount = true,
  addColumnLeafAreaIndex = true,
  addColumnImageUrl = false,
  addColumnGli = false,
) => {
  return api.get(`/experiment/${experimentUuid}/greenhouse/${greenhouseName}/results`, {
    params: {
      company: companyName,
      report_type: reportType,
      file_type: fileType,
      analisis_type: analisisType,
      include_previous_experiments: includePreviousExperiments,
      add_column_plant_ammount: addColumnPlantAmmount,
      add_column_leaf_area_index: addColumnLeafAreaIndex,
      add_column_url_img_tray: addColumnImageUrl,
      add_column_gli: addColumnGli,
    }
  });
}

export const getUrlOfExperimentResultsV2 = async (
  companyName,
  greenhouseName,
  experimentUuid,
  reportType,
  analisisType,
  includePreviousExperiments = true,
  addColumnPlantAmmount = true,
  addColumnLeafAreaIndex = true,
  addColumnImageUrl = false,
  addColumnGli = false,
) => {
  return api.get(`/experiment/${experimentUuid}/greenhouse/${greenhouseName}/results_v2`, {
    params: {
      company: companyName,
      greenhouse: greenhouseName,
      report_type: reportType,
      analisis_type: analisisType,
      include_previous_experiments: includePreviousExperiments,
      add_column_plant_ammount: addColumnPlantAmmount,
      add_column_leaf_area_index: addColumnLeafAreaIndex,
      add_column_url_img_tray: addColumnImageUrl,
      add_column_gli: addColumnGli,
    }
  });
}


export const getGalleryItems = async (
  companyName,
  greenhouseName,
  experiment_uuid,
  filterValue,
  filterType,
  includePreviousExperiments = true,
) => {
  let res = await api.get(`/experiments/${experiment_uuid}/greenhouses/${greenhouseName}/gallery_items`, {
    params: {
      company: companyName,
      tray_code: filterType === "tray_code" ? filterValue : null,
      tray_position: filterType === "tray_position" ? filterValue : null,
      include_previous_experiments: includePreviousExperiments,
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return res;
}


export const makeImagesTimelapse = async (
  companyName,
  greenhouseName,
  trays,
) => {
  const body = {
    "trays": trays,
  };
  let res = await api.post(`/images/tray/greenhouses/${greenhouseName}/timelapse`, body, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
}


export const generateTimelapses = async (
  companyName,
  greenhouseName,
  experimentUuid,
  listUrls,
  fileName,
  shouldSendByEmail = false,
) => {
  const body = {
    "list_urls": listUrls,
    "name": fileName,
  }
  const res = await api.post(`/greenhouses/${greenhouseName}/experiments/${experimentUuid}/generate_timelapses`, body, {
    params: {
      company: companyName,
      should_send_by_email: shouldSendByEmail,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
};


export const concatenateTimelapses = async (
  greenhouseName,
  experimentUuid,
  companyName,
  listUrls,
  fileName,
) => {
  const body = {
    "list_urls": listUrls,
    "name": fileName,
  }

  try {
    let res = await api.post(`/greenhouses/${greenhouseName}/experiments/${experimentUuid}/generate_timelapses`, body, {
      params: {
        company: companyName,
      },
    });

    if (res.status !== 201) {
      throw res;
    }

    return res.data;
  } catch (error) {
    console.error('Error to generate the timelapses:', error);
    throw error;
  }
}


export const downloadImages = async (
  companyName,
  greenhouseName,
  experimentUuid,
  listUrls,
  fileName,
) => {
  const body = {
    "list_urls": listUrls,
    "name": fileName,
  }
  const res = await api.post(`/greenhouses/${greenhouseName}/experiments/${experimentUuid}/download_zip_images`, body, {
    params: {
      company: companyName,
    },
  });
  if (res.status !== 201) {
    throw res;
  }
  return res;
};