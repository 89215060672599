import { format } from 'date-fns';

export function formatDate(date) {
  const offset = date.getTimezoneOffset();
  const lateDate = new Date(date.getTime() - (offset * 60 * 1000)); // delay date to adjust with Date.prototype.toISOString()
  const iso8601Date = lateDate.toISOString().slice(0, 10);
  return iso8601Date;
}

export function parseAndFormatDate(dateString){
  const date = dateString ? new Date(dateString) : null;
  return date && !isNaN(date) ? format(date, 'dd/MM/yyyy') : null;
};