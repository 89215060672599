import {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {
  Container,
  Form,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import ReactLoading from 'react-loading';
import * as FaIcons from "react-icons/fa";
import {
  parse,
  isBefore,
  isAfter,
  addMonths
} from 'date-fns';
import axios from 'axios';
import fileDownload from 'js-file-download';

import DropdownOfExperiments from 'components/DropdownOfExperiments';
import DropdownOfTrayCodes from 'components/DropdownOfTrayCodes';
import DayRangePicker from 'components/DayRangePicker';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import { RequiredField } from 'components/FormFieldTip';
import { MenuContext } from 'contexts/menu';
import {
  listExperiments,
  makeImagesTimelapse,
  generateTimelapses,
  getMultipleTrays,
  downloadImages,
} from 'services/api';

import './index.scss';


const TimeLapsePage = () => {
  const { selectedCompany, selectedGreenhouse } = useContext(MenuContext);

  const today = new Date();
  const defaultDayRangeValue = {
    from: addMonths(today, -6),
    to: today
  };
  const [dayRangeOfInactiveExperiments, setDayRangeOfInactiveExperiments] = useState(defaultDayRangeValue);
  const [isValidDayRangeOfInactiveExperiments, setIsValidDayRangeOfInactiveExperiments] = useState(true);
  const [isActiveDayRangeOfTrayImagesField, setIsActiveDayRangeOfTrayImagesField] = useState(false);

  const [activeExperiments, setActiveExperiments] = useState(null);
  const [inactiveExperiments, setInactiveExperiments] = useState(null);
  const [selectedExperiment, setSelectedExperiment] = useState(null);
  const [isValidSelectedExperiment, setIsValidSelectedExperiment] = useState(true);
  const [isActiveExperimentField, setIsActiveExperimentField] = useState(false);

  const [isActiveSearchBtn, setIsActiveSearchBtn] = useState(false);

  const [hour, setHour] = useState(null);
  const [mapHour, setMapHour] = useState(null);
  const [code, setCode] = useState(null);
  const [mapCode, setMapCode] = useState(null);
  const [url, setUrl] = useState([]);

  const [trayCodes, setTrayCodes] = useState([]);
  const [selectedTrayCodes, setSelectedTrayCodes] = useState([]);

  const [dayRangeOfTrayImages, setDayRangeOfTrayImages] = useState(null);

  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingTimelapse, setIsLoadingTimelapse] = useState(false);
  const [isLoadingImages, setisLoadingImages] = useState(false);

  const [imagesTimelapseData, setImagesTimelapseData] = useState(null);


  const updateActiveExperiments = useCallback(async (companyName, greenhouseName) => {
    // get active experiments
    const data = {};
    try {
      const res = await listExperiments(
        companyName,
        greenhouseName,
        true,
      );
      for (const exp of res.data) {
        const end_date = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());
        const today = new Date();
        if (end_date && isBefore(end_date, today)) {
          continue;
        }

        const item = {
          ...exp,
          start_date: parse(exp.exp_start_date, 'yyyyMMdd', new Date()),
        };
        data[exp.uuid] = item;
      }
    } catch (ex) {
      console.error('ex:', ex);
      return;
    }
    setActiveExperiments(data);
  }, [setActiveExperiments]);


  const updateInactiveExperiments = useCallback(async (
    companyName,
    greenhouseName,
    fromDate,
    toDate
  ) => {
    // get inactive experiments
    const data = {};
    try {
      const res = await listExperiments(
        companyName,
        greenhouseName,
        false,
        fromDate,
        toDate,
      );
      for (const exp of res.data) {
        if (!exp.end_date) {
          continue;
        }

        const item = {
          ...exp,
          start_date: exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date()),
          end_date: exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date()),
        };
        data[exp.uuid] = item;
      }
    } catch (ex) {
      console.error('ex:', ex);
      return;
    }
    setInactiveExperiments(data);
  }, [setInactiveExperiments]);


  useEffect(() => {
    if (selectedCompany && selectedGreenhouse) {
      updateActiveExperiments(selectedCompany.name, selectedGreenhouse.name);
      updateInactiveExperiments(
        selectedCompany.name,
        selectedGreenhouse.name,
        dayRangeOfInactiveExperiments.from,
        dayRangeOfInactiveExperiments.to,
      );
      setSelectedExperiment(null);
    }
  }, [
    selectedCompany,
    selectedGreenhouse,
    updateActiveExperiments,
    updateInactiveExperiments,
    dayRangeOfInactiveExperiments,
  ]);


  useEffect(() => {
    setIsActiveExperimentField(!!dayRangeOfInactiveExperiments);
  }, [
    dayRangeOfInactiveExperiments,
  ]);


  useEffect(() => {
    setIsActiveDayRangeOfTrayImagesField(!!selectedExperiment);
    setIsActiveSearchBtn(!!selectedExperiment);
  }, [
    selectedExperiment,
  ]);


  const divideListIntoColumns = (list, columnCount) => {
    const dividedList = [];
    const itemsPerColumn = Math.ceil(list.length / columnCount);

    for (let i = 0; i < columnCount; i++) {
      const startIndex = i * itemsPerColumn;
      const endIndex = startIndex + itemsPerColumn;
      const columnList = list.slice(startIndex, endIndex);
      dividedList.push(columnList);
    }

    return dividedList;
  }


  const handleCheckboxChange = (event) => {
    const index = parseInt(event.target.dataset.index, 10);
    const selectedCode = mapCode[index];
    const selectedHour = mapHour[index];
    setCode(selectedCode);
    setHour(selectedHour);

    let selectedUrls = [...url];

    const targetIndex = parseInt(event.target.getAttribute("data-index"));

    if (targetIndex >= 0) {
      const checkboxes = Array.from(document.getElementsByTagName('input')).filter(
        (checkbox) => parseInt(checkbox.getAttribute('data-index'), 10) === targetIndex
      );

      checkboxes.forEach((checkbox) => {
        checkbox.checked = event.target.checked;

        const checkboxUrl = checkbox.id;
        const checkboxUrlStartsWithHttps = checkboxUrl.startsWith("https");

        if (checkbox.checked && !selectedUrls.includes(checkboxUrl) && checkboxUrlStartsWithHttps) {
          selectedUrls.push(checkboxUrl);
        } else if (!checkbox.checked && selectedUrls.includes(checkboxUrl)) {
          selectedUrls = selectedUrls.filter((url) => url !== checkboxUrl);
        }
      });
    }

    selectedUrls = selectedUrls.filter((url) => url.startsWith("http"));
    setUrl(selectedUrls);

    const columnCount = getMaxTargetIndex(selectedUrls);
    console.log('Número de colunas selecionadas:', columnCount);
  };

  const getMaxTargetIndex = (selectedUrls) => {
    const selectedIndices = new Set();

    selectedUrls.forEach((url) => {
      const targetIndex = parseInt(document.getElementById(url)?.getAttribute('data-index'), 10);
      if (!isNaN(targetIndex)) {
        selectedIndices.add(targetIndex);
      }
    });

    return Array.from(selectedIndices);
  };


  // const getMaxTargetIndex = (selectedUrls) => {
  //   let maxTargetIndex = -1;

  //   selectedUrls.forEach((url) => {
  //     const targetIndex = parseInt(document.getElementById(url)?.getAttribute('data-index'), 10);
  //     if (targetIndex > maxTargetIndex) {
  //       maxTargetIndex = targetIndex;
  //     }
  //   });

  //   return maxTargetIndex + 1;
  // };


  function handleDayRangeOfInactiveExperimentsToggle(isOpen, event, metadata) {
    setIsValidDayRangeOfInactiveExperiments(!!dayRangeOfInactiveExperiments);
    if (!isOpen && selectedExperiment) {
      const isValidSelectedExperiment = !!inactiveExperiments[selectedExperiment.uuid];
      setIsValidSelectedExperiment(isValidSelectedExperiment);
      if (isValidSelectedExperiment) {
        if (dayRangeOfTrayImages && (
          isBefore(dayRangeOfTrayImages.from, dayRangeOfInactiveExperiments.from) ||
          isAfter(dayRangeOfTrayImages.to, dayRangeOfInactiveExperiments.to)
        )) {
          setDayRangeOfTrayImages(null);
        }
      } else {
        setSelectedExperiment(null);
        setDayRangeOfTrayImages(null);
      }
    }
  }


  const handleExperimentDropdownToggle = (isOpen, event, metadata) => {
    if (!isOpen) {
      setIsValidSelectedExperiment(!!selectedExperiment);
    }
  }


  const handleDropdownOfExperimentsSelect = (eventKey, event) => {
    event.preventDefault();

    const experiment = activeExperiments[eventKey] || inactiveExperiments[eventKey];
    if (selectedExperiment && selectedExperiment.uuid === experiment.uuid) {
      setSelectedExperiment(null);
      setDayRangeOfTrayImages(null);
      return;
    }
    setSelectedExperiment(experiment);

    (async () => {
      let res = await getMultipleTrays(
        selectedCompany.name,
        selectedGreenhouse.name,
        experiment.tray_uuids,
        [
          'uuid',
          'code',
        ]
      );
      console.log('getMultipleTrays res:', res);
      const trays = res.data.data.map(item => ({
        'id': item.uuid,
        'name': item.code
      })).filter(item => item.name);
      setTrayCodes(trays)
      console.log('getMultipleTrays trays:', trays);
    })();
  }


  const handleDropdownOfTrayCodesSelect = (eventKey, event) => {
    console.log('handleDropdownOfTrayCodesSelect:', eventKey, event);
    event.preventDefault();

    const trayCode = trayCodes.find(item => item.id === eventKey);
    console.log('trayCode:', trayCode);
    console.log('selectedTrayCodes:', selectedTrayCodes);
    if (selectedTrayCodes.some(item => item.name === trayCode.name)) {
      setSelectedTrayCodes(selectedTrayCodes.filter(item => item.name !== trayCode.name));
      return;
    }

    setSelectedTrayCodes([...selectedTrayCodes, trayCode]);
  }


  const handleSearchBtnClick = async (e) => {
    e.preventDefault();
    setIsSearching(true);
    const trays = selectedTrayCodes.map(item => ({
      "uuid": item.id,
      "code": item.name,
    }));
    const res = await makeImagesTimelapse(
      selectedCompany.name,
      selectedGreenhouse.name,
      trays,
    );

    console.log("codigos", res.data.data)
    const horarios = res.data.data.columns;
    const day = res.data.data.index;
    const data = res.data.data.data;

    const dayData = day.filter((d) => d.length > 1);

    const timeCodeData = horarios.reduce((acc, [time, code]) => {
      if (time !== undefined || code !== undefined) {
        acc.push({
          time: time,
          code: code,
        });
      }
      return acc;
    }, []);

    const times = timeCodeData.map(item => item.time);
    const codes = timeCodeData.map(item => item.code);
    setMapCode(codes);
    setMapHour(times);

    const imagesData = dayData.map((d) => {
      const index = day.indexOf(d);
      const dayImages = data[index];
      return dayImages || [];
    });

    const formattedData = dayData.map((dayItem, i) => {
      const timeCodeItem = timeCodeData[i] || {};
      const images = imagesData[i] || [];
      return {
        day: dayItem || '',
        time: timeCodeItem.time !== undefined ? timeCodeItem.time : '',
        code: timeCodeItem.code !== undefined ? timeCodeItem.code : '',
        images: images,
      };
    });
    setIsSearching(false);
    setImagesTimelapseData(formattedData);
  };


  const handleDownloadTimelapse = async () => {
    setIsLoadingTimelapse(true);

    // try to generate the timelapse
    // if it fails due to timeout, try to send it by email
    let shouldSendByEmail = false;
    const fileName = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${code}_${hour}`;
    const listUrls = url;

    try {
      const columnCount = getMaxTargetIndex(listUrls);
      console.log("testando", columnCount.length)

      if (columnCount.length > 1) {
        const dividedUrls = divideListIntoColumns(listUrls, columnCount.length);
        const requests = dividedUrls.map((urls, index) => {
          console.log("novo", mapCode[columnCount[index]])
          const columnCode = mapCode[columnCount[index]];
          const columnHour = mapHour[columnCount[index]];
          const columnFileName = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${columnCode}_${columnHour}`;
          console.log("name teste", columnFileName)
          return generateTimelapses(
            selectedCompany.name,
            selectedGreenhouse.name,
            selectedExperiment.uuid,
            urls,
            columnFileName,
            shouldSendByEmail
          );
        });

        await Promise.all(requests)
          .then((results) => {
            console.log('results:', results);
            const fileDownloadPromises = results.map((result, index) => {
              const url = result.data.data;
              const urlSplit = url.replace(/"/g, '');
              const columnCode = mapCode[columnCount[index]];
              const columnHour = mapHour[columnCount[index]];
              const fileNameVideo = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${columnCode}_${columnHour}.mp4`;
              return axios.get(urlSplit, {
                responseType: 'blob',
              }).then((res) => {
                fileDownload(res.data, fileNameVideo);
              });
            });

            return Promise.all(fileDownloadPromises);
          })
          .catch((error) => {
            console.log('error:', error);
            shouldSendByEmail = true;
          });
      } else {
        const result = await generateTimelapses(
          selectedCompany.name,
          selectedGreenhouse.name,
          selectedExperiment.uuid,
          listUrls,
          fileName,
          shouldSendByEmail
        );

        console.log('result:', result);
        const url = result.data.data;
        const urlSplit = url.replace(/"/g, '');
        const fileNameVideo = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${code}_${hour}.mp4`;
        axios.get(urlSplit, {
          responseType: 'blob',
        }).then((res) => {
          fileDownload(res.data, fileNameVideo);
        });
      }
    } catch (error) {
      console.log('error:', error);
      shouldSendByEmail = true;
    }

    // try to generate the timelapse again, only sending it by email
    if (shouldSendByEmail) {
      await Swal.fire({
        icon: 'warning',
        title: 'Aviso!',
        text: "O vídeo do timelapse está demorando muito tempo para processar e iremos te enviar pelo email!",
      });

      try {
        const result = await generateTimelapses(
          selectedCompany.name,
          selectedGreenhouse.name,
          selectedExperiment.uuid,
          listUrls,
          fileName,
          shouldSendByEmail
        );

        console.log('result:', result);
        const url = result.data.data;
        const urlSplit = url.replace(/"/g, '');
        const fileNameVideo = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${code}_${hour}.mp4`;
        axios.get(urlSplit, {
          responseType: 'blob',
        }).then((res) => {
          fileDownload(res.data, fileNameVideo);
        });
      } catch (error) {
        console.log('error:', error);
      }
    }

    setIsLoadingTimelapse(false);
  }

  const handleDownloadImagesBtnClick = async () => {
    setisLoadingImages(true);

    const fileName = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${code}_${hour}`
    const listUrls = url

    try {
      const result = await downloadImages(
        selectedCompany.name,
        selectedGreenhouse.name,
        selectedExperiment.uuid,
        listUrls,
        fileName
      );


      setisLoadingImages(false);
      const url = result.data.data
      const urlSplit = url.replace(/"/g, '');
      const fileNameZip = `Timelapse_${selectedCompany.name}_${selectedGreenhouse.name}_${selectedExperiment.name}_${code}_${hour}.zip`;


      axios.get(urlSplit, {
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, fileNameZip);
      });
    } catch (error) {
      console.log('error:', error)
      setisLoadingImages(true);

    }
  }

  const handleDayRangeOfInactiveExperimentsSelect = async (
    range, /** The day that was selected (or clicked) triggering the event. */
    selectedDay, /** The modifiers of the selected day. */
    activeModifiers,
    e,
  ) => {
    e.preventDefault();
    console.log(range);
    console.log(selectedDay);
    console.log(activeModifiers);
    console.log(e);
    if (!isActiveDayRangeOfTrayImagesField) {
      return;
    }

    setDayRangeOfInactiveExperiments(range);
  }

  return (
    <>
      <Container fluid className="TimeLapsePage Content">
        <div className="Form">
          <Row>
            <Form.Group as={Row} controlId="formDayRange">
              <Form.Label column xs={3} sm={4} md={3} lg={1}>
                <RequiredField />Período do experimento:
              </Form.Label>
              <Col className={"d-flex align-items-center"}>
                <DayRangePicker
                  range={dayRangeOfInactiveExperiments}
                  isValid={isValidDayRangeOfInactiveExperiments}
                  canBeIndividualDate={false}
                  toDate={today}
                  onToggle={handleDayRangeOfInactiveExperimentsToggle}
                  onRangeSelect={handleDayRangeOfInactiveExperimentsSelect}
                />
              </Col>
              <Form.Label column xs={4} sm={4} md={3} lg={1}>
                <RequiredField />Códigos:
              </Form.Label>
              <Col className={"d-flex align-items-center"}>
                <DropdownOfTrayCodes
                  items={trayCodes}
                  selectedItems={selectedTrayCodes}
                  // isValid={isValidExperiment}
                  onSelect={handleDropdownOfTrayCodesSelect}
                  onToggle={handleExperimentDropdownToggle}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formDayRange">
              <Form.Label column xs={3} sm={4} md={3} lg={1}>
                <RequiredField />Experimento:
              </Form.Label>
              <Col className={"d-flex align-items-center"}>
                <DropdownOfExperiments
                  activeExperiments={activeExperiments}
                  inactiveExperiments={inactiveExperiments}
                  selectedItem={selectedExperiment}
                  onToggle={handleExperimentDropdownToggle}
                  onSelect={isActiveExperimentField ? handleDropdownOfExperimentsSelect : () => {}}
                  isDisabled={!isActiveExperimentField}
                // isValid={isValidSelectedExperiment}
                />
              </Col>
              <Col>
                {isSearching ? (
                  <Button
                    variant="primary"
                    className="Button FormGroup"
                    aria-disabled={true}
                    aria-label="Consultando fotos da galeria..."
                    title="Consultando fotos da galeria..."
                  >
                    <ReactLoading
                      className="ReactLoading"
                      type={"spin"}
                      color={"#fff"}
                      height={21}
                      width={21}
                    />
                    Consultando fotos...
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    title="Consultar fotos do timelapse"
                    onClick={handleSearchBtnClick}
                  >
                    <FaIcons.FaSearch /> Consultar fotos
                  </Button>
                )}
              </Col>
            </Form.Group>
            <Form.Group className="mt-2">
              <Row>
                <Col xs={6}>
                  {isLoadingTimelapse ? (
                    <Button
                      variant="danger"
                      className="Button FormGroup"
                      aria-disabled={true}
                      aria-label="Processando timelapse..."
                      title="Processando timelapse..."
                    >
                      <ReactLoading
                        className="ReactLoading"
                        type={"spin"}
                        color={"#fff"}
                        height={21}
                        width={21}
                      />
                      Processando timelapse...
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      title="Baixar timelapse"
                      onClick={handleDownloadTimelapse}
                      disabled={!isActiveSearchBtn}
                    >
                      <FaIcons.FaDownload /> Baixar TimeLapse
                    </Button>
                  )}

                  {isLoadingImages ? (
                    <Button
                      variant="success"
                      className="Button FormGroup"
                      aria-disabled={true}
                      aria-label="Processando imagens..."
                      title="Processando imagens..."
                    >
                      <ReactLoading
                        className="ReactLoading"
                        type={"spin"}
                        color={"#fff"}
                        height={21}
                        width={21}
                      />
                      Processando imagens...
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      title="Baixar imagens"
                      style={{ textAlign: 'left', marginLeft: '10px' }}
                      onClick={handleDownloadImagesBtnClick}
                      disabled={!isActiveSearchBtn}
                    >
                      <FaIcons.FaDownload /> Baixar Imagens
                    </Button>
                  )}
                </Col>
              </Row>
            </Form.Group>

          </Row>
        </div>
        <Col>
          <div className="TimeLapseContainer mt-5">
            {imagesTimelapseData && (
              <Table striped bordered hover>
                <thead key={"tabela"}>
                  <tr>
                    <th>Horário</th>
                    {imagesTimelapseData.map((data, index) => {
                      if (data.time !== '') {
                        return (
                          <th key={`th-${index + 1}`}>
                            <div className="Form2">
                              {['checkbox'].map((hour) => (
                                <div key={`default-${hour}`} className="text-left">
                                  <Form.Check
                                    type={hour}
                                    id={index + 1}
                                    data-index={index}
                                    onChange={handleCheckboxChange}
                                    label={data.time}
                                  />
                                </div>
                              ))}
                            </div>
                          </th>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </tr>
                  <tr>
                    <th>Código</th>
                    {imagesTimelapseData.map((data, index) => (
                      data.code !== '' && <th key={`code-${index}`}>{data.code}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {imagesTimelapseData.map((data, dataIndex) => (
                    <tr key={`row-${dataIndex}`}>
                      <td>{data.day.split("-").reverse().join("/")}</td>
                      {data.images.map((image, index) => (
                        <td key={`image-${index}`}>
                          <div className="Form2">
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                              <Form.Check
                                type="checkbox"
                                id={image}
                                data-index={index}
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  const imageUrl = event.target.id;

                                  if (isChecked) {
                                    // Adicionar a URL a urlList
                                    setUrl(prevUrlList => [...prevUrlList, imageUrl]);
                                  } else {
                                    // Remover a URL de urlList
                                    const updatedUrlList = url.filter(url => url !== imageUrl);
                                    setUrl(updatedUrlList);
                                  }
                                }}
                                data-link={image}
                                className="custom-checkbox"
                                style={{
                                  position: 'absolute',
                                  top: '10px',
                                  left: '10px',
                                }}
                              />
                              <img
                                src={image}
                                alt={`Imagem ${index + 1}`}
                                className="image-style"
                              />
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </Col>
      </Container>
    </>
  )
}

export default TimeLapsePage;
