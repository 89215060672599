import {
  useContext,
  useEffect,
} from 'react';

import { AuthContext } from 'contexts/auth';
import { getQueryStringParams } from 'utils/browser';


const LoginPage = () => {
  const { isLoading, isAuthenticated, navigate, loginWithAuthorizationCode } = useContext(AuthContext);
  
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const params = getQueryStringParams();
      if (params.code) {
        loginWithAuthorizationCode(params.code);
      } else {
        const redirectUrl = `https://estufa-live.auth.sa-east-1.amazoncognito.com/login
          ?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}
          &response_type=code
          &scope=email+openid+profile
          &redirect_uri=${window.location.href}
        `.replace(/\s/g, "");
        if (localStorage.getItem("DEV")) {
          const logMsg = `redirectUrl: ${redirectUrl}`;
          console.log(logMsg);
          alert(logMsg);
        }
        window.location.href = redirectUrl;
      }
    } else if (isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated, navigate, loginWithAuthorizationCode]);

  return;
}

export default LoginPage;