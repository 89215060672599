import { useState, forwardRef } from 'react'
import './styles.scss'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6'
import { getRootVar } from 'utils/generalFunctions'
import { useTranslation } from 'react-i18next'
import Text from 'components/Text'
import { RiInformation2Line, RiTimeLine } from 'react-icons/ri'
import { Tooltip } from 'react-tooltip'

export const Input = forwardRef(
  (
    {
      className,
      type,
      label,
      bootstrapClass,
      placeholder,
      style,
      value,
      width,
      password,
      onChange,
      required,
      disabled,
      small,
      info,
      time,
      tooltipInfoId,
      ...props
    },
    ref
  ) => {
    const theme = document.querySelector("body").getAttribute("data-theme");
    const [show, setShow] = useState(false)
    const { t } = useTranslation()

    const handleClick = () => setShow(!show)

    return (
      <div
        className="label-title"
        style={{
          ...style,
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? ".6" : "1"
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {label &&
              <div className="d-flex gap-1">
                <div>{label}</div>
                {required && <Text className="smallTxt" color={getRootVar("--red")}>*</Text>}
              </div>
            }
            {info && (
              <>
                <div data-tooltip-id={tooltipInfoId}>
                  <RiInformation2Line color={getRootVar("--gray3")} size="14" />
                </div>
                <Tooltip
                  id={tooltipInfoId}
                  style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                  {info}
                </Tooltip>
              </>
            )}
          </div>
          {time && (
            <>
              <div data-tooltip-id="time-tooltip" className="d-flex align-items-center gap-1">
                <RiTimeLine color={getRootVar("--gray3")} size="14" />
                <Text className="tinyTxt" color={getRootVar("--gray3")}>{time}</Text>
              </div>
              <Tooltip
                id="time-tooltip"
                style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                <Text className="tinyTxt">Você tem {time} para editar essa informação. Após esse período, o campo será bloqueado.</Text>
              </Tooltip>
            </>
          )}
        </div>
        <input
          type={!password ? 'text' : show === true ? 'text' : 'password'}
          style={{
            width: width,
            backgroundColor: theme === "dark" && getRootVar("--altWhite"),
            border: `1px solid ${theme === "light" ? getRootVar("--gray4") : getRootVar("--altWhite")}`
          }}
          ref={ref}
          {...props}
          className={small ? "inputSmall" : "input"}
          value={value}
          onChange={onChange}
          placeholder={t(placeholder)}
        />

        {password && (
          <div onClick={handleClick} style={{ cursor: 'pointer' }}>
            {show === true ? (
              <FaRegEye color={getRootVar("--gray3")} style={{ marginLeft: '-30px' }} />
            ) : (
              <FaRegEyeSlash
                color={getRootVar("--gray3")}
                style={{ marginLeft: '-30px' }}
              />
            )}
          </div>
        )}
      </div>
    )
  }
)

export default Input
