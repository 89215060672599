
// Por último, importamos tudo e exportamos um único objeto

import ptBrTranslations from './pt-br'
import enUsTranslations from './en-us'
import esESTranslations from './es-es'

export default {
  'pt-BR': ptBrTranslations,  // Atente-se a usar as abreviaturas corretas
  'en-US': enUsTranslations,   // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
  'es-ES': esESTranslations   // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
}