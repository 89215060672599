import React, { useState } from 'react';
import {
  Dropdown,
  FormControl,
} from 'react-bootstrap';

import "./index.scss";


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef((
  {
    children,
    onClick,

    isDisabled,
    isValid,
  },
  ref
) => (
  <span
    className={isDisabled ? 'text-secondary' : isValid ? 'link-white' : 'link-danger'}
    style={{
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Digite para filtrar..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => {
            if (!value || child.props.role === "heading") {
              return true;
            }
            return child.props.children.toLowerCase().startsWith(value);
          })}
        </ul>
      </div>
    );
  },
);

export default function DropdownOfTrayCodes({
  items,
  selectedItems = null,
  isDisabled = false,
  isValid = true,
  onSelect = (
    eventKey, // any
    event,    // object
  ) => {
    event.preventDefault();
    // console.log('DropdownWithSearch onSelect:', eventKey, event);
  },
  onToggle = (
    nextShow, // boolean
    meta,     // 'click','keydown','rootClose', or 'select' 
  ) => {
    // console.log('DropdownWithSearch onToggle:', nextShow, meta);
  },
}) {
  return (
    <Dropdown
      className="DropdownOfTrayCodes"
      autoClose="outside"
      onSelect={onSelect}
      onToggle={onToggle}
      title={selectedItems.length ? selectedItems.map(item => item.name).join(" | ") : "Selecionar códigos"}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        isDisabled={isDisabled}
        isValid={isValid}
      >
        {selectedItems.length ? selectedItems.map(item => item.name).join(" | ") : "Selecionar códigos"}
      </Dropdown.Toggle>


      <Dropdown.Menu
        as={CustomMenu}
        className="DropdownMenu"
      >
        {items.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => (
          <Dropdown.Item
            key={`${id}_${name}`}
            as="button"
            className="Item"
            eventKey={id}
            active={selectedItems.length && selectedItems.some(item => item.id === id)}
            title={name}
          >
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}