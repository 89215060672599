import Text from 'components/Text'
import './styles.scss'
import { RiCloseLine } from 'react-icons/ri'
import Button from 'components/Button'
import { getRootVar } from 'utils/generalFunctions'

export default function Modal(props) {
  const { children, title, confirm, dangerModal, handleCloseModal, confirmModal, disabled, isLoading, scroll = true } = props

  return (
    <div className="backgroundBlur">
      <div className="modalWrapper">
        <div className="header">
          <Text className="bold">{title}</Text>
          <RiCloseLine onClick={!isLoading && handleCloseModal} color={isLoading ? getRootVar("--gray4") : getRootVar("--gray3")} size={24} style={{ cursor: "pointer" }} />
        </div>
        <div className="content" style={{ overflowY: !scroll && "initial" }}>
          {children}
        </div>
        {confirm &&
          <div className="footer">
            <Button disabled={disabled} isLoading={isLoading} onClick={() => confirmModal()} className={`${dangerModal ? "red" : "primary"} fluid`}><Text className="bold white">
              {confirm}</Text>
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
