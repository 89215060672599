import $ from "jquery";
import { Link } from 'react-router-dom';

import "./index.scss"; 


const NotFoundPage = () => {
  var pageX = $(document).width();
  var pageY = $(document).height();

  $(document).on("mousemove", event => {
    //verticalAxis
    const mouseY = event.pageY;
    const yAxis = (pageY / 2 - mouseY) / pageY * 300;
    //horizontalAxis
    const mouseX = event.pageX / -pageX;
    const xAxis = -mouseX * 100 - 100;

    $(".box__ghost-eyes").css({ 
      "transform": `translate(${xAxis}%, -${yAxis}%)`
    });
  });

  return (
    <div className="NotFoundPage">
      <div className="box">
        <div className="box__ghost">
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>

          <div className="box__ghost-container">
            <div className="box__ghost-eyes">
              <div className="box__eye-left"></div>
              <div className="box__eye-right"></div>
            </div>
            <div className="box__ghost-bottom">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="box__ghost-shadow"></div>
        </div>

        <div className="box__description">
          <div className="box__description-container">
            <div className="box__description-title">Whoops!</div>
            <div className="box__description-text">Parece que não conseguimos encontrar a página que você estava procurando...</div>
          </div>
          <Link className="box__button" to="/" title="Início">Ir para a tela inicial</Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage;