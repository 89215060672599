import * as BsIcons from "react-icons/bs";
import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

// import "./index.scss";


export default function FormFieldTip({ 
  message,
  isValid = true,
}) {
  return (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip>{message}</Tooltip>}
    >
      <span>
        <BsIcons.BsQuestionCircleFill
          className={isValid ? "text-primary" : "text-danger"}
        />
      </span>
    </OverlayTrigger>
  );
}


export function RequiredField() {
  return (
    <strong className="text-danger" title="Campo obrigatório">*</strong>
  );
}
