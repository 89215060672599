import React, { useState } from 'react';
import ReactDOMServer from "react-dom/server";
import {
  Dropdown,
  FormControl,
  InputGroup,
  Button,
  Table,
} from 'react-bootstrap';
import { parseAndFormatDate } from 'utils/date';
import * as FcIcons from "react-icons/fc";
import diacritic from 'diacritic';
import { format } from 'date-fns';
import "./index.scss";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef((
  {
    children,
    isDisabled,
    isValid,
    onClick,
    title,
  },
  ref
) => (
  <span
    className={isDisabled ? 'text-secondary' : isValid ? 'link-primary' : 'link-danger'}
    style={{
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    title={title}
  >
    {children}
    &#x25bc;
  </span>
));


// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef((
  {
    children,
    style,
    className,
    'aria-labelledby': labeledBy,
  },
  ref
) => {
  const [searchTerm, setSearchTerm] = useState('');


  const handleClearFilterBtnClick = (e) => {
    setSearchTerm('');
  }

  const calculateScore = (item, searchTerm) => {
    // Function to calculate score based on search term match
    if (item === searchTerm) {
      return 2; // Exact match: high score (2)
    } else if (item.startsWith(searchTerm)) {
      return 1; // Starts with search term: medium score (1)
    } else if (item.includes(searchTerm)) {
      return 0.5; // Contains search term: low score (0.5)
    } else if (item.indexOf(searchTerm) > -1) {
      return 0.25; // Search term found in any position: minimum score (0.25)
    } else {
      return 0; // No match: minimum score (0)
    }
  };

  const removeAccents = (str) => {
    return diacritic.clean(str);
  };

  const trimString = (str) => {
    // Remove leading and trailing whitespace from the string
    // using the regular expression /^\s+|\s+$/g
    // and replace it with an empty string ('')
    const trimmed = str.replace(/^\s+|\s+$/g, '');

    // Replace consecutive whitespace with a single space
    // using the regular expression /\s+/g
    const result = trimmed.replace(/\s+/g, ' ');

    return result;
  }

  const groups = [];
  const childGroups = [];
  React.Children.toArray(children).map((child) => {
    if (child.props.role === "heading") {
      groups.push(child);
      childGroups.push([]);
    } else {
      const table = ReactDOMServer.renderToStaticMarkup(child.props.children);
      const parser = new DOMParser();
      const doc = parser.parseFromString(table, "text/html");
      const scores = [].map.call(doc.querySelectorAll('td'), td => {
        return calculateScore(
          removeAccents(trimString(td.textContent).toLowerCase()),
          removeAccents(trimString(searchTerm).toLowerCase())
        );
      });
      const score = Math.max(...scores);
      childGroups[childGroups.length - 1].push({
        child,
        score,
      });
    }
    return null;
  });

  const items = [];
  groups.map((group, index) => {
    items.push(group);

    const childGroup = childGroups[index].filter((result) => result.score > 0)
      .sort((a, b) => b.score - a.score)
      .map((result) => result.child);
    items.push(...childGroup);

    return null;
  });

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <div className="DropdownFormGroup">
        <InputGroup>
          <FormControl
            autoFocus
            className="FormControl mx-3 my-2 w-auto"
            placeholder="Digite para filtrar..."
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            title={searchTerm}
          />
          <Button className="Button" variant="secondary" title="Limpar filtro" onClick={handleClearFilterBtnClick}><FcIcons.FcClearFilters /></Button>
        </InputGroup>
      </div>
      <ul className="list-unstyled">
        {items.map((item) => item)}

      </ul>
    </div>
  );
});

export default function DropdownOfExperiments({
  activeExperiments,
  inactiveExperiments,
  selectedItem = null,
  onSelect = (
    eventKey, // any
    event,    // object
  ) => {
    event.preventDefault();
    // console.log('DropdownWithSearch onSelect:', eventKey, event);
  },
  onToggle = (
    nextShow, // boolean
    meta,     // 'click','keydown','rootClose', or 'select' 
  ) => {
    // console.log('DropdownWithSearch onToggle:', nextShow, meta);
  },
  isDisabled = false,
  isValid = true,
  shouldDisplayGroupOfActiveExperiments = true,
}) {
  return (
    <Dropdown
      className="DropdownOfExperiments"
      autoClose="outside"
      onSelect={onSelect}
      onToggle={onToggle}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        title={selectedItem ? selectedItem.name : "Selecionar um experimento"}
        isDisabled={isDisabled}
        isValid={isValid}
      >
        {selectedItem ? selectedItem.name : "Selecionar um experimento"}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="DropdownMenu"
      >
        {shouldDisplayGroupOfActiveExperiments && (
          <Dropdown.Header>
            <h5>Experimentos Ativos</h5>
            <Table>
              <thead>
                <tr>
                  <th>Início</th>
                  <th>Término</th>
                  <th>Nome</th>
                </tr>
              </thead>
            </Table>
          </Dropdown.Header>
        )}
        {shouldDisplayGroupOfActiveExperiments && activeExperiments && Object.entries(activeExperiments)
          .sort(([aKey, aValue], [bKey, bValue]) => bValue.name.localeCompare(aValue.name))
          .sort(([aKey, aValue], [bKey, bValue]) => new Date(bValue.start_date) - new Date(aValue.start_date))
          .map(([key, value]) => (
            <Dropdown.Item
              key={key}
              as="button"
              className="Item"
              eventKey={key}
              active={selectedItem && selectedItem.uuid === key}
            >
              <Table bordered>
                <tbody>
                  <tr>
                    <td title={parseAndFormatDate(value.start_date)}>{parseAndFormatDate(value.start_date)}</td>
                    <td title={parseAndFormatDate(value.end_date)}>{parseAndFormatDate(value.end_date)}</td>
                    <td title={value.name}>{value.name}</td>
                  </tr>
                </tbody>
              </Table>
            </Dropdown.Item>
          ))
        }

        <Dropdown.Header className='mt-3'>
          <h5>Experimentos Finalizados</h5>
          <Table>
            <thead>
              <tr>
                <th>Início</th>
                <th>Término</th>
                <th>Nome</th>
              </tr>
            </thead>
          </Table>
        </Dropdown.Header>
        {inactiveExperiments && Object.entries(inactiveExperiments)
          .sort(([aKey, aValue], [bKey, bValue]) => bValue.name.localeCompare(aValue.name))
          .sort(([aKey, aValue], [bKey, bValue]) => new Date(bValue.end_date) - new Date(aValue.end_date))
          .map(([key, value]) => (
            <Dropdown.Item
              key={key}
              as="button"
              className="Item"
              eventKey={key}
              active={selectedItem && selectedItem.uuid === key}
            >
              <Table bordered>
                <tbody>
                  <tr>
                    <td title={parseAndFormatDate(value.start_date)}>{parseAndFormatDate(value.start_date)}</td>
                    <td title={parseAndFormatDate(value.end_date)}>{parseAndFormatDate(value.end_date)}</td>
                    <td title={value.name}>{value.name}</td>
                  </tr>
                </tbody>
              </Table>
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}
