import React, { 
  // useState 
} from 'react';
import {
  Dropdown,
} from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { 
  format, 
  // isSameMonth, 
  // isWithinInterval,
} from 'date-fns';
// import { Button } from 'react-bootstrap';
import ptBR from 'date-fns/locale/pt-BR';

import './index.scss';


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef((
  {
    children,
    onClick,
    isDisabled,
    isValid,
  },
  ref
) => (
  <span
    className={isDisabled ? 'text-secondary' : isValid ? 'link-primary' : 'link-danger'}
    style={{
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef((
  {
    children,
    style,
    className,
    'aria-labelledby': labeledBy,
  },
  ref
) => {
  // const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      {children}
    </div>
  );
},
);

export default function DayRangePicker({
  range,
  isDisabled = false,
  isValid = true,
  canBeIndividualDate = true,
  onToggle = null,
  fromDate = null,
  toDate = null,
  onRangeSelect = (
    range, /** The day that was selected (or clicked) triggering the event. */
    selectedDay, /** The modifiers of the selected day. */
    activeModifiers,
    e,
  ) => {
    console.log(range);
    console.log(selectedDay);
    console.log(activeModifiers);
    console.log(e);
  },
}) {
  let status = <span>Selecionar período</span>;
  if (range?.from) {
    if (!canBeIndividualDate && !range.to) {
      range.to = range.from;
    }
    
    if (!range.to) {
      status = <span>A partir de {format(range.from, 'dd/MM/yyyy')}</span>;
    } else if (range.to) {
      status = (
        <span>
          {format(range.from, 'dd/MM/yyyy')} a {format(range.to, 'dd/MM/yyyy')}
        </span>
      );
    }
  }

  // const today = new Date();
  // const footer = (!(fromDate && toDate) || isWithinInterval(today, { start: fromDate, end: toDate })) && (
  //   <Button
  //     variant="primary"
  //     disabled={isSameMonth(today, month)}
  //     onClick={() => setMonth(today)}
  //   >
  //     Ir para hoje
  //   </Button>
  // );

  // console.log({fromDate, toDate, range});
  return (
    <Dropdown
      className="DayRangePicker"
      onToggle={onToggle}
    >
      <Dropdown.Toggle as={CustomToggle} isDisabled={isDisabled} isValid={isValid}>
        {status}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu} 
        className="DropdownMenu"
      >
        <DayPicker
          mode="range"
          locale={ptBR}
          fromDate={fromDate}
          toDate={toDate}
          selected={range}
          onSelect={onRangeSelect}
          // month={month}
          // setMonth={setMonth}
          // footer={footer}
          showOutsideDays
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
