import React, { useState } from 'react';
import "./index.scss";
import Text from 'components/Text';
import { RiArrowDownSLine, RiArrowUpSLine, RiSearchLine } from "react-icons/ri"
import { getRootVar } from 'utils/generalFunctions';
import { useTranslation } from 'react-i18next';

export default function DropdownOfGreenhouses({
  items,                // [{ "name": "estufa-basf-21_06_2022", ... }, { "name": "estufa-basf-06", ... }]
  selectedItem = null,  // { "name": "estufa-basf-06", ... }
  isDisabled = false,
  isValid = true,
  onToggle = null,
  onClickItem = null,
}) {

  const [input, setInput] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState()
  const { t } = useTranslation()

  const filteredItems = input && input.length > 0
    ? items.filter(e => e.name && e.name
      .toLowerCase()
      .replace(/[\s-]/g, '')
      .includes(input.replace(/[\s-]/g, '').toLowerCase()))
    : items

  const handleClickItem = () => {
    onClickItem && setDropdownOpen(!dropdownOpen)
  }

  return (
    <div className="Dropdown">
      <div className="DropdownToggle" onClick={() => setDropdownOpen(!dropdownOpen)}>
        <Text>Estufa</Text>
        <Text>{selectedItem ? selectedItem.name : "Selecionar"}</Text>
        {dropdownOpen ? <RiArrowUpSLine color={getRootVar("--gray3")}/> : <RiArrowDownSLine color={getRootVar("--gray3")} />}
      </div>
      {dropdownOpen &&
        <div className="DropdownBody">
          <div className="DropdownHeader">
            <div className="SearchBar">
              <input type="text"
                onChange={(e) => setInput(e.target.value)}
                value={input}
                placeholder={t('Pesquisar estufa')}
                className="SearchInput" />
              <RiSearchLine color={getRootVar("--gray3")} />
            </div>
          </div>
          <div className={`"DropdownContent" ${filteredItems && filteredItems.length > 0 && "DropdownContentNoScroll"}`}>
            {filteredItems && filteredItems.length > 0 ? filteredItems.map(item => (
              <div className={`"DropdownItem" ${selectedItem && item.name === selectedItem.name && "DropdownItemActive"}`}
                id={item.name}
                key={item.name}
                eventKey={item.name}
                onClick={() => handleClickItem()}
              >
                {item.name}
              </div>
            )) : <Text className="NoResults">Nenhum resultado encontrado.</Text>}
          </div>
        </div>
      }
    </div>
  );
}
