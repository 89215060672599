import { createRoot } from 'react-dom/client';
import App from './App';
import "./index.scss";
import { Provider } from 'react-redux';
import { store } from './store';
import './i18n'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

localStorage.getItem("selectedTheme") === "dark"
    ? document.querySelector("body").setAttribute("data-theme", "dark")
    : document.querySelector("body").setAttribute("data-theme", "light")

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);