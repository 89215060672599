import {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import { AuthContext } from 'contexts/auth';

import {
  listCompanies,
  getCompany,
  listGreenhouses,
} from 'services/api';
import { delay } from 'utils/time';
import Menu from 'components/Menu';

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const { user, loginWithRefreshToken } = useContext(AuthContext);

  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [greenhouses, setGreenhouses] = useState([]);
  const [isLoadingGreenhouses, setIsLoadingGreenhouses] = useState(false);
  const [selectedGreenhouse, setSelectedGreenhouse] = useState(null);


  useEffect(() => {
    const updateCompanies = async () => {
      setIsLoadingCompanies(true);

      const items = [];
      try {
        const res = await listCompanies();
        for (const company of res.data) {
          items.push({
            name: company.name,
          });
        }
      } catch (ex) {
        console.error('updateCompanies ex:', ex);
        delay(1000);
        (async () => {
          await loginWithRefreshToken();
          window.location.reload();
        })();
        return;
      }
      if (items.length > 0) {
        items.sort((a, b) => a.name.localeCompare(b.name));

        setCompanies(items);

        // configure selected company based on the last configured company
        const companyName = localStorage.getItem("companyName");
        setSelectedCompany(companyName ? items.find(item => item.name === companyName) : items[0]);
        setSelectedGreenhouse(null);
      }

      setIsLoadingCompanies(false);
    }

    const updateCompany = async () => {
      setIsLoadingCompanies(true);

      const res = await getCompany();
      setCompanies([res.data.data]);
      setSelectedCompany(res.data.data);
      setSelectedGreenhouse(null);

      setIsLoadingCompanies(false);
    }

    if (user.role === "admin") {
      updateCompanies();
    } else {
      updateCompany();
    }
  }, [
    user.username,
    user.role,
    setCompanies,
    setIsLoadingCompanies,
    setSelectedCompany,
    loginWithRefreshToken
  ]);


  useEffect(() => {
    const updateGreenhouses = async (companyName) => {
      setIsLoadingGreenhouses(true);

      const items = [];
      try {
        const res = await listGreenhouses(companyName);
        for (const greenhouse of res.data) {
          items.push({
            name: greenhouse.name,
            geometry: greenhouse.geometry_map,
            benches_geometry: greenhouse.benches_geometry_map,
            trays_of_last_scan_geometry: greenhouse.trays_of_last_scan_geometry_map,
            config: greenhouse.config,
          });
        }
      } catch (ex) {
        console.error('updateGreenhouses ex:', ex);
        return;
      }
      if (items.length > 0) {
        items.sort((a, b) => a.name.localeCompare(b.name));

        // configure selected greenhouse based on the last configured greenhouse
        const greenhouseName = localStorage.getItem("greenhouseName");

        setSelectedGreenhouse(greenhouseName ? items.find(item => item.name === greenhouseName) : items[0]);

        setGreenhouses(items);
      }

      setIsLoadingGreenhouses(false);
    }

    if (selectedCompany) {
      updateGreenhouses(selectedCompany.name);
    }
  }, [
    selectedCompany,
    setSelectedGreenhouse,
    setGreenhouses,
    setIsLoadingGreenhouses,
  ]);


  const handleChangeCompany = (e) => {
    const company = companies.find(item => item.name === e.target.closest('.dropdown-item').id);
    setSelectedCompany(company);
    setSelectedGreenhouse(null);

    // updates company in local storage
    localStorage.setItem("companyName", company.name);
  }


  const handleChangeGreenhouse = (e) => {
    const greenhouse = greenhouses.find(item => item.name === e.target.closest('.dropdown-item').id);
    setSelectedGreenhouse(greenhouse);

    // updates greenhouse in local storage
    localStorage.setItem("greenhouseName", greenhouse.name);
  }


  return (
    <MenuContext.Provider
      value={{
        selectedCompany,
        selectedGreenhouse,
      }}
    >
      {/* <MenuPrimary
        companies={companies}
        selectedCompany={selectedCompany}
        isLoadingCompanies={isLoadingCompanies}
        onChangeCompany={handleChangeCompany}

        greenhouses={greenhouses}
        selectedGreenhouse={selectedGreenhouse}
        isLoadingGreenhouses={isLoadingGreenhouses}
        onChangeGreenhouse={handleChangeGreenhouse}
      /> */}
      <Menu />
      {children}
    </MenuContext.Provider>
  )
}