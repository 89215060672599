export const getRootVar = (color) => {
    const theme = document.querySelector("body").getAttribute("data-theme");

    const style = theme
        ? getComputedStyle(document.querySelector(`[data-theme="${theme}"]`))
        : getComputedStyle(document.documentElement);

    return style.getPropertyValue(color).trim();
}

//transform date into the "dd/mm/yyyy" format
const selectedLanguage = localStorage.getItem("selectedLanguage");

export const formatDate = (date) => {
    let day = ""
    let month = ""
    let year = ""

    if (typeof date === "string") {
        [day, month, year] = date.split('/');
    } else {
        day = String(date.getDate()).padStart(2, '0');
        month = String(date.getMonth() + 1).padStart(2, '0');
        year = date.getFullYear();
    }

    if (selectedLanguage === "en-US") {
        return `${month}/${day}/${year}`;
    } else {
        return `${day}/${month}/${year}`;
    }
};