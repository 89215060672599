import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Select';
import Text from 'components/Text';

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();

  const languages = [
    { value: "pt-BR", label: t("Português (Brasil)") },
    { value: "en-US", label: t("Inglês (EUA)") },
    { value: "es-ES", label: t("Espanhol") }
  ];

  const selectedLanguage = languages.find(option => option.value === i18n.language);
  const [language, setLanguage] = useState(selectedLanguage || languages[0]);

  const onChange = (selectedLang) => {
    i18n.changeLanguage(selectedLang.value);
    setLanguage(selectedLang);
    localStorage.setItem('selectedLanguage', selectedLang.value);
  };

  return (
    <Select
      label={<Text className="bold smallTxt">Idioma</Text>}
      options={languages}
      value={{ value: language.value, label: t(language.label) }}
      clear={false}
      onChange={(e) => onChange(e)}
    />
  );
};

export default SelectLanguage;
