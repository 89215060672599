// src/features/experiment/experimentSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const experimentSlice = createSlice({
  name: 'experiment',
  initialState: {
    selectedTrayLayers: []
  },
  reducers: {
    setSelectedTrayLayers: (state, action) => {
      state.selectedTrayLayers = action.payload;
    }
  },
});

export const { setSelectedTrayLayers } = experimentSlice.actions;
export default experimentSlice.reducer;
